import { InjectedConnector } from "@web3-react/injected-connector"
import { WalletConnectConnector } from "@web3-react/walletconnect-connector"
import { CHAIN_ID, RPC } from "./constants"

export const injected = new InjectedConnector({
    supportedChainIds: [CHAIN_ID],
})

export const walletconnect = new WalletConnectConnector({
    rpc: { [CHAIN_ID]: [RPC] },
    bridge: 'https://bridge.walletconnect.org',
    qrcode: true,
    pollingInterval: 15000,
})