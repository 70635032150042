import React from 'react'
import Header from '../components/Header'
import BackToTop from '../components/BackToTop'


function Games() {
    return (
        <div className="projects" data-theme="dark" data-menu="fixed" data-footer-effect="on" data-footer-corners="on">
            <Header />
            <main className="ms-main">
                <div className="ms-page-content">
                    {/*================= Banner Area Start =================*/}
                    <section className="ms-hero services">
                        <div className="ms-parallax jarallax-img" data-speed="0.7" data-type="scroll">
                            <video className='w-100' autoPlay muted loop src="https://d1cihhbb125h48.cloudfront.net/1694439977.mp4" />
                            <div className="ms-hc">
                                <div className="ms-hc--inner">
                                    <p className="ms-hero-subtitle">Games</p>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/*================= Banner Area End =================*/}
                    {/*================= Services Area Start =================*/}
                    <div className="services-area-2 services">
                        <div className="container">
                            <div className="services-area-inner">
                                <div className="services-bottom">
                                    <div className="services-item">
                                        <div className="row">
                                            <div className="col-lg-4 col-md-6 text-center">
                                                <div className="ms-sb img-top">
                                                    <div className="ms-sb--img boxed one">
                                                        <img src="/assets/images/icon/game/diverse_game_library.svg" className="attachment-full size-full" alt="image" />
                                                    </div>
                                                    <div className="ms-sb--inner">
                                                        <h3 className="ms-sb--title">
                                                            <span>Diverse Game Library</span>
                                                        </h3>
                                                        <p className="ms-sb--text">Dive into a wide range of games, from
                                                            action-packed adventures to strategic puzzles, social games, and
                                                            more.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 text-center">
                                                <div className="ms-sb img-top">
                                                    <div className="ms-sb--img boxed one">
                                                        <img src="/assets/images/icon/game/social_gaming_experiene.svg" className="attachment-full size-full" alt="image" />
                                                    </div>
                                                    <div className="ms-sb--inner">
                                                        <h3 className="ms-sb--title">
                                                            <span>Social Gaming Experience</span>
                                                        </h3>
                                                        <p className="ms-sb--text">Connect, compete, and collaborate
                                                            with friends and players worldwide in interactive multiplayer
                                                            modes.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 text-center">
                                                <div className="ms-sb img-top">
                                                    <div className="ms-sb--img boxed one">
                                                        <img src="/assets/images/icon/game/blockchain_casinos.svg" className="attachment-full size-full" alt="image" />
                                                    </div>
                                                    <div className="ms-sb--inner">
                                                        <h3 className="ms-sb--title">
                                                            <span>Blockchain Casinos</span>
                                                        </h3>
                                                        <p className="ms-sb--text">Engage in transparent and fair online casinos,
                                                            leveraging blockchain technology for unbiased outcomes where
                                                            laws permit, ensuring a safe and compliant gaming environment.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 text-center">
                                                <div className="ms-sb img-top">
                                                    <div className="ms-sb--img boxed one">
                                                        <img src="/assets/images/icon/game/legal_betting_opportunities.svg" className="attachment-full size-full" alt="image" />
                                                    </div>
                                                    <div className="ms-sb--inner">
                                                        <h3 className="ms-sb--title">
                                                            <span>Legal Betting Opportunities</span>
                                                        </h3>
                                                        <p className="ms-sb--text">Participate in betting events where
                                                            laws permit, ensuring a safe and compliant gaming environment.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 text-center">
                                                <div className="ms-sb img-top">
                                                    <div className="ms-sb--img boxed one">
                                                        <img src="/assets/images/icon/game/play_to_earn_mechanism.svg" className="attachment-full size-full" alt="image" />
                                                    </div>
                                                    <div className="ms-sb--inner">
                                                        <h3 className="ms-sb--title">
                                                            <span>Play-to-Earn Mechanism</span>
                                                        </h3>
                                                        <p className="ms-sb--text">Skill and strategy in games can lead to
                                                            real-world rewards, enhancing the gaming experience.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 text-center">
                                                <div className="ms-sb img-top">
                                                    <div className="ms-sb--img boxed one">
                                                        <img src="/assets/images/icon/game/exclusive_in_game_assets.svg" className="attachment-full size-full" alt="image" />
                                                    </div>
                                                    <div className="ms-sb--inner">
                                                        <h3 className="ms-sb--title">
                                                            <span>Exclusive In-Game Assets</span>
                                                        </h3>
                                                        <p className="ms-sb--text">Acquire unique in-game items,
                                                            characters, and skins, adding flair to your gaming persona.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 text-center">
                                                <div className="ms-sb img-top">
                                                    <div className="ms-sb--img boxed one">
                                                        <img src="/assets/images/icon/game/cross_platform_play.svg" className="attachment-full size-full" alt="image" />
                                                    </div>
                                                    <div className="ms-sb--inner">
                                                        <h3 className="ms-sb--title">
                                                            <span>Cross-Platform Play</span>
                                                        </h3>
                                                        <p className="ms-sb--text">Enjoy a seamless gaming experience across
                                                            devices, ensuring you're always in the game.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 text-center">
                                                <div className="ms-sb img-top">
                                                    <div className="ms-sb--img boxed one">
                                                        <img src="/assets/images/icon/game/commnity_tornaments_events.svg" className="attachment-full size-full" alt="image" />
                                                    </div>
                                                    <div className="ms-sb--inner">
                                                        <h3 className="ms-sb--title">
                                                            <span>Community Tournaments &amp; Events</span>
                                                        </h3>
                                                        <p className="ms-sb--text">Join or host tournaments,
                                                            fostering a sense of community and friendly competition.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 text-center">
                                                <div className="ms-sb img-top">
                                                    <div className="ms-sb--img boxed one">
                                                        <img src="/assets/images/icon/game/regulat_game_updates.svg" className="attachment-full size-full" alt="image" />
                                                    </div>
                                                    <div className="ms-sb--inner">
                                                        <h3 className="ms-sb--title">
                                                            <span>Regular Game Updates</span>
                                                        </h3>
                                                        <p className="ms-sb--text">Stay engaged with frequent content
                                                            additions, feature enhancements, and game updates.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 text-center">
                                                <div className="ms-sb img-top">
                                                    <div className="ms-sb--img boxed one">
                                                        <img src="/assets/images/icon/game/safe_respectful_environment.svg" className="attachment-full size-full" alt="image" />
                                                    </div>
                                                    <div className="ms-sb--inner">
                                                        <h3 className="ms-sb--title">
                                                            <span>Safe &amp; Respectful Environment</span>
                                                        </h3>
                                                        <p className="ms-sb--text">Prioritize player safety with anticheat
                                                            systems, and maintain a respectful community atmosphere.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 text-center">
                                                <div className="ms-sb img-top">
                                                    <div className="ms-sb--img boxed one">
                                                        <img src="/assets/images/icon/game/legal_compliance.svg" className="attachment-full size-full" alt="image" />
                                                    </div>
                                                    <div className="ms-sb--inner">
                                                        <h3 className="ms-sb--title">
                                                            <span>Legal Compliance</span>
                                                        </h3>
                                                        <p className="ms-sb--text">Adhere to regional laws and regulations,
                                                            ensuring a compliant and trustworthy gaming platform.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 text-center">
                                                <div className="ms-sb img-top">
                                                    <div className="ms-sb--img boxed one">
                                                        <img src="/assets/images/icon/game/feedback_driven_evolutions.svg" className="attachment-full size-full" alt="image" />
                                                    </div>
                                                    <div className="ms-sb--inner">
                                                        <h3 className="ms-sb--title">
                                                            <span>Feedback-Driven Evolution</span>
                                                        </h3>
                                                        <p className="ms-sb--text">Actively incorporate player insights
                                                            and feedback, ensuring the platform remains dynamic and usercentric.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 text-center">
                                                <div className="ms-sb img-top">
                                                    <div className="ms-sb--img boxed one">
                                                        <img src="/assets/images/icon/game/developer_casino_partnership.svg" className="attachment-full size-full" alt="image" />
                                                    </div>
                                                    <div className="ms-sb--inner">
                                                        <h3 className="ms-sb--title">
                                                            <span>Developer &amp; Casino Partnerships</span>
                                                        </h3>
                                                        <p className="ms-sb--text">Collaborate with game
                                                            developers and casino operators, enriching the platform's offerings
                                                            and experiences.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*================= Services Area End =================*/}
                </div>
            </main>

            <BackToTop />
        </div>
    )
}

export default Games