import React, { useEffect, useState } from 'react'
import Header from '../components/Header'
import { useWeb3React } from '@web3-react/core'
import useAuth from '../hooks/useAuth'
import { injected } from '../connectors'
import useContract from '../hooks/useContract'
import { NFT_CONTRACT_ADDRESS, TOKEN_CONTRACT_ADDRESS } from '../constants'
import NFT_ABI from '../abis/nft.json'
import TOKEN_ABI from '../abis/token.json'
import BackToTop from '../components/BackToTop'
import cogoToast from 'cogo-toast'
import copy from 'copy-to-clipboard'
import axios from 'axios'
import PurchaseNFTModal from '../components/PurchaseNFTModal'
import { ROOT_URL } from '../components/rootURL'

function PurchaseNFT() {

  const [loading, setLoading] = useState(false)
  const [eliteType, setEliteType] = useState('')
  const [showModal, setShowModal] = useState(false)
  const [isValid, setIsValid] = useState(false);
  const { active, account } = useWeb3React()
  const [addNftToWallet, setAddNftToWallet] = useState({})
  const { login } = useAuth()

  const nftContract = useContract(NFT_CONTRACT_ADDRESS, NFT_ABI)
  const tokenContract = useContract(TOKEN_CONTRACT_ADDRESS, TOKEN_ABI)

  const getRef = async () => {
    try {
      const res = await axios.post(`${ROOT_URL}/get_ref`, {
        address: account
      })
      if (res.data.status == 'success') {
        setIsValid(res.data.is_valid)
        setAddNftToWallet({
          tokenId: res.data.token_id,
          eliteType: res.data.elite_type
        })
      } else {
        setIsValid(false)
        setAddNftToWallet({})
      }
    } catch (error) {
      cogoToast.error(error.message, { position: 'top-right' });
      console.log('Error.get-ref', error.message)
    }
  }

  const checkRef = async (refAddress) => {
    try {
      setLoading(true)
      const res = await axios.post(`${ROOT_URL}/check_ref`, {
        ref_address: refAddress
      })
      if (res.data.status == 'success') {
        return true
      } else {
        setLoading(false)
        cogoToast.error(res.data.message, { position: 'top-right' });
        return false
      }
    } catch (error) {
      setLoading(false)
      cogoToast.error(error.message, { position: 'top-right' });
      return false
    }
  }

  useEffect(() => {
    if (account) {
      getRef()
    }
  }, [account])

  const handleBuy = async (type) => {
    if (loading && eliteType != type) {
      return
    }
    if (active && account) {
      setEliteType(type);
      setShowModal(true);
    } else {
      await login(injected)
    }
  }

  const buy = async (refAddress, setRefAddress) => {
    try {
      if (loading) {
        return
      }
      if (refAddress) {
        if (!await checkRef(refAddress)) {
          return
        }
      }
      if (tokenContract && nftContract) {
        setLoading(true)
        const price = await nftContract.methods.price(eliteType).call()
        if (price) {
          const allowd = await tokenContract.methods.allowance(account, NFT_CONTRACT_ADDRESS).call()
          if (Number(allowd) < Number(price)) {
            await tokenContract.methods.approve(NFT_CONTRACT_ADDRESS, price).send({ from: account })
          }
          // const approveTx = await tokenContract.methods.approve(NFT_CONTRACT_ADDRESS, price).send({ from: account })
          const buyTx = await nftContract.methods.BUY(eliteType).send({ from: account })
          const res = await axios.post(`${ROOT_URL}/add_ref`, {
            address: account,
            ref_address: refAddress,
            amount: price,
            transaction_hash: buyTx.transactionHash,
            token_id: buyTx.events.Transfer.returnValues.tokenId,
            elite_type: eliteType
          })
          if (res.data.status == 'success') {
            setShowModal(false)
            setRefAddress('')
            getRef()
            cogoToast.success(res.data.message, { position: 'top-right' });
          } else {
            cogoToast.error(res.data.message, { position: 'top-right' });
          }
        }
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      cogoToast.error(error.message, { position: 'top-right' });
      console.log('Error.buy', error.message)
    }
  }

  const addToWallet = async (eliteType) => {
    try {
      await window.ethereum.request({
        method: 'wallet_watchAsset',
        params: {
          type: 'ERC721',
          options: {
            address: NFT_CONTRACT_ADDRESS,
            tokenId: addNftToWallet?.tokenId
          },
        },
      })
      cogoToast.success('NFT added to wallet!', { position: 'top-right' });
      setAddNftToWallet({})
    } catch (err) {
      cogoToast.error(err.message, { position: 'top-right' });
      console.log('err.addToWallet', err.message)
    }
  }

  return (
    <div>
      <Header />
      <main className="ms-main nft_main">
        <div className="container">
          <div id="primary" className="content-area single-product">
            <div className="site-main">
              <div className="woocommerce-notices-wrapper" />
              <div id="product-470" className="ms-single-product product type-product post-470 status-publish first instock product_cat-run product_tag-life product_tag-move product_tag-sport product_tag-trainers has-post-thumbnail shipping-taxable purchasable product-type-simple">
                <div className='mb-5'>
                  <h2 className="ms-single-product_title">Purchase NFT</h2>
                </div>
                <div className='d_grid'>
                  <div className='nft_card'>
                    <div className='nft_coin'>
                      <img src="/assets/images/product/bronze_coin.png" className='w-100' alt='coin' />
                    </div>
                    <div className='d-flex justify-content-between align-items-center'>
                      <p className='coin_title m-0'>Bronze Elite</p>
                      <p className='coin_price'>100 <span>USDT</span></p>
                    </div>
                    <div className='mt10'>
                      <button className='mint_btn btn w-100' onClick={() => handleBuy('BRONZE_ELITE')}>
                        {!active ? 'Connect Wallet' : loading && eliteType == 'BRONZE_ELITE' ? 'Loading...' : 'Buy'}
                      </button>
                      {
                        active && account &&
                        <button
                          className='purchase_nft btn_purchase btn w-100 mt-3'
                          disabled={!(addNftToWallet.tokenId && addNftToWallet.eliteType == 'BRONZE_ELITE')}
                          onClick={() => addToWallet()}
                        >
                          Add to Wallet
                        </button>
                      }
                    </div>
                  </div>
                  <div className='nft_card'>
                    <div className='nft_coin'>
                      <img src="/assets/images/product/silver_coin.png" className='w-100' alt='coin' />
                    </div>
                    <div className='d-flex justify-content-between align-items-center'>
                      <p className='coin_title'>Silver Elite</p>
                      <p className='coin_price'>500 <span>USDT</span></p>
                    </div>
                    <div className='mt10'>
                      <button className='mint_btn btn w-100' onClick={() => handleBuy('SILVER_ELITE')}>
                        {!active ? 'Connect Wallet' : loading && eliteType == 'SILVER_ELITE' ? 'Loading...' : 'Buy'}
                      </button>
                      {
                        active && account &&
                        <button
                          className='purchase_nft btn_purchase btn w-100 mt-3'
                          disabled={!(addNftToWallet.tokenId && addNftToWallet.eliteType == 'SILVER_ELITE')}
                          onClick={() => addToWallet()}
                        >
                          Add to Wallet
                        </button>
                      }
                    </div>
                  </div>
                  <div className='nft_card'>
                    <div className='nft_coin'>
                      <img src="/assets/images/product/gold_coin.png" className='w-100' alt='coin' />
                    </div>
                    <div className='d-flex justify-content-between align-items-center'>
                      <p className='coin_title'>Gold Elite</p>
                      <p className='coin_price'>1000 <span>USDT</span></p>
                    </div>
                    <div className='mt10'>
                      <button className='mint_btn btn w-100' onClick={() => handleBuy('GOLD_ELITE')}>
                        {!active ? 'Connect Wallet' : loading && eliteType == 'GOLD_ELITE' ? 'Loading...' : 'Buy'}
                      </button>
                      {
                        active && account &&
                        <button
                          className='purchase_nft btn_purchase btn w-100 mt-3'
                          disabled={!(addNftToWallet.tokenId && addNftToWallet.eliteType == 'GOLD_ELITE')}
                          onClick={() => addToWallet()}
                        >
                          Add to Wallet
                        </button>
                      }
                    </div>
                  </div>
                  <div className='nft_card'>
                    <div className='nft_coin'>
                      <img src="/assets/images/product/diamond_elite.png" className='w-100' alt='coin' />
                    </div>
                    <div className='d-flex justify-content-between align-items-center'>
                      <p className='coin_title'>Diamond Elite</p>
                      <p className='coin_price'>3000 <span>USDT</span></p>
                    </div>
                    <div className='mt10'>
                      <button className='mint_btn btn w-100' onClick={() => handleBuy('DIAMOND_ELITE')}>
                        {!active ? 'Connect Wallet' : loading && eliteType == 'DIAMOND_ELITE' ? 'Loading...' : 'Buy'}
                      </button>
                      {
                        active && account &&
                        <button
                          className='purchase_nft btn_purchase btn w-100 mt-3'
                          disabled={!(addNftToWallet.tokenId && addNftToWallet.eliteType == 'DIAMOND_ELITE')}
                          onClick={() => addToWallet()}
                        >
                          Add to Wallet
                        </button>
                      }
                    </div>
                  </div>
                  <div className='nft_card'>
                    <div className='nft_coin'>
                      <img src="/assets/images/product/platinum_coin.png" className='w-100' alt='coin' />
                    </div>
                    <div className='d-flex justify-content-between align-items-center'>
                      <p className='coin_title'>Platinum Elite</p>
                      <p className='coin_price'>5000 <span>USDT</span></p>
                    </div>
                    <div className='mt10'>
                      <button className='mint_btn btn w-100' onClick={() => handleBuy('PLATINUM_ELITE')}>
                        {!active ? 'Connect Wallet' : loading && eliteType == 'PLATINUM_ELITE' ? 'Loading...' : 'Buy'}
                      </button>
                      {
                        active && account &&
                        <button
                          className='purchase_nft btn_purchase btn w-100 mt-3'
                          disabled={!(addNftToWallet.tokenId && addNftToWallet.eliteType == 'PLATINUM_ELITE')}
                          onClick={() => addToWallet()}
                        >
                          Add to Wallet
                        </button>
                      }
                    </div>
                  </div>
                </div>
                <div className='referral_card'>
                  <div className='row'>
                    {
                      account ?
                        <>
                          {
                            isValid ?
                              <>
                                <div className='col-xl-8 col-12'>
                                  <div className='referral_title'>
                                    <h3>Earn Exciting Rewards by Referring Friends to Buy NFTs</h3>
                                    <p>Invite your friends and family to purchase NFTs from our website, and
                                      you'll unlock fantastic rewards as a token of our appreciation. Start
                                      referring today.
                                    </p>
                                  </div>
                                </div>
                                <div className='referral_link'>
                                  <div className='d-flex justify-content-between'>
                                    <label>Referral URL</label>
                                  </div>
                                  <div className='referral_box'>
                                    <img src='/assets/images/icon/link.svg' className='link_icon' alt='link icon' />
                                    <p>
                                      {window.location.origin}/purchase/nft?ref={account || '-'}
                                    </p>
                                    <img
                                      src='/assets/images/icon/copy_icon.svg'
                                      className='link_icon copy_icon'
                                      alt='copy icon'
                                      style={{ cursor: 'pointer' }}
                                      onClick={() => {
                                        copy(`${window.location.origin}/purchase/nft?ref=${account || '-'}`)
                                        cogoToast.success('Copied!', { position: 'top-right' })
                                      }}
                                    />
                                  </div>
                                </div>
                              </>
                              :
                              <div className='col-xl-8 col-12'>
                                <div className='referral_title'>
                                  <h3>You are not Eligible for the Referral</h3>
                                  <p>Purchase at least one NFT to unlock the opportunity to refer your
                                    friends and start your referral journey.
                                  </p>
                                </div>
                              </div>
                          }
                        </>
                        :
                        <div className='col-xl-8 col-12'>
                          <div className='referral_title'>
                            <h3>Please Connect Wallet</h3>
                            <p>Unlock your referral URL and referral details by connecting your wallet.</p>
                          </div>
                        </div>
                    }
                  </div>
                </div>
                <div className='referral_card mt-4'>
                  <div className='row'>
                    <div className='col-xl-8 col-12'>
                      <div className="btn-wrap">
                        <a className="btn btn-circle btn--md" target="_blank" role="button" href="/assets/images/doc/card_english.pdf">
                          <div className="ms-btn--circle">
                            <div className="circle">
                              <div className="circle-fill" />
                              <svg viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg" className="circle-outline">
                                <circle cx={25} cy={25} r={23} />
                              </svg>
                              <div className="circle-icon">
                                <svg viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg" className="icon-arrow">
                                  <path d="M0 5.65612V4.30388L8.41874 4.31842L5.05997 0.95965L5.99054 0L10.9923 4.97273L6.00508 9.96L5.07451 9.00035L8.43328 5.64158L0 5.65612Z">
                                  </path>
                                </svg>
                              </div>
                            </div>
                          </div>
                          <div className="ms-btn--label">
                            <div className="ms-btn__text">Rewards Doc (English)</div>
                            <div className="ms-btn__border" />
                          </div>
                        </a>
                      </div>
                      <div className="btn-wrap mt_10">
                        <a className="btn btn-circle btn--md" target="_blank" role="button" href="/assets/images/doc/card_korean.pdf">
                          <div className="ms-btn--circle">
                            <div className="circle">
                              <div className="circle-fill" />
                              <svg viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg" className="circle-outline">
                                <circle cx={25} cy={25} r={23} />
                              </svg>
                              <div className="circle-icon">
                                <svg viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg" className="icon-arrow">
                                  <path d="M0 5.65612V4.30388L8.41874 4.31842L5.05997 0.95965L5.99054 0L10.9923 4.97273L6.00508 9.96L5.07451 9.00035L8.43328 5.64158L0 5.65612Z">
                                  </path>
                                </svg>
                              </div>
                            </div>
                          </div>
                          <div className="ms-btn--label">
                            <div className="ms-btn__text">Rewards Doc (Korean)</div>
                            <div className="ms-btn__border" />
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <PurchaseNFTModal
        showModal={showModal}
        setShowModal={setShowModal}
        eliteType={eliteType}
        buy={(refAddress, setRefAddress) => buy(refAddress, setRefAddress)}
        loading={loading}
      />
      <BackToTop />
    </div>
  )
}

export default PurchaseNFT