import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';

export default function PurchaseNFTModal({ showModal, setShowModal, eliteType, buy, loading }) {

  const [refAddress, setRefAddress] = useState('');

  useEffect(() => {
    if (showModal && !loading) {
      if (window.location.href.split('?ref=')?.[1]) {
        setRefAddress(window.location.href.split('?ref=')[1].slice(0, 42))
      }
    }
  }, [showModal, loading])

  const handleCloseModal = () => {
    if (!loading && !window.location.href.split('?ref=')[1]) {
      setRefAddress('');
    }
    setShowModal(false);
  }

  return (
    <Modal
      show={showModal}
      onHide={handleCloseModal}
      dialogClassName='referral_modal'
      contentClassName='modal_bg'
      centered
    >
      <Modal.Header closeButton className='border-0 pb-0' onClick={handleCloseModal}>
      </Modal.Header>
      <Modal.Body className='modal_body pt-0'>
        <div className='cion_img text-center'>
          {eliteType == 'BRONZE_ELITE' && <img src='/assets/images/product/bronze_coin.png' className='w-100' alt='coin' />}
          {eliteType == 'SILVER_ELITE' && <img src='/assets/images/product/silver_coin.png' className='w-100' alt='coin' />}
          {eliteType == 'GOLD_ELITE' && <img src='/assets/images/product/gold_coin.png' className='w-100' alt='coin' />}
          {eliteType == 'PLATINUM_ELITE' && <img src='/assets/images/product/platinum_coin.png' className='w-100' alt='coin' />}
          {eliteType == 'VIP_ELITE' && <img src='/assets/images/product/vip_coin.png' className='w-100' alt='coin' />}
        </div>
        <div className='text-center mb_30'>
          {eliteType == 'BRONZE_ELITE' && <h2 className='modal_title bronze_title'>Bronze Elite</h2>}
          {eliteType == 'SILVER_ELITE' && <h2 className='modal_title silver_title'>Silver Elite</h2>}
          {eliteType == 'GOLD_ELITE' && <h2 className='modal_title gold_title'>Gold Elite</h2>}
          {eliteType == 'PLATINUM_ELITE' && <h2 className='modal_title platinum_title'>Platinum Elite</h2>}
          {eliteType == 'VIP_ELITE' && <h2 className='modal_title vip_title'>VIP Elite</h2>}
          <p className='coin_text'>
            Your progress this is Awesome. let's keep it <br className='d-md-block d-none' /> up and get a lot of points reward !
          </p>
        </div>
        <div className='coin_amount d-flex justify-content-between align-items-center mb_30'>
          <p className='coin_text'>Price (USDT)</p>
          {eliteType == 'BRONZE_ELITE' && <p className='coin_text text-white'>100</p>}
          {eliteType == 'SILVER_ELITE' && <p className='coin_text text-white'>500</p>}
          {eliteType == 'GOLD_ELITE' && <p className='coin_text text-white'>1000</p>}
          {eliteType == 'PLATINUM_ELITE' && <p className='coin_text text-white'>5000</p>}
          {eliteType == 'VIP_ELITE' && <p className='coin_text text-white'>10000</p>}
        </div>
        <div className='referral_link mb_30'>
          <label>Referral Address (Optional)</label>
          <div className='position-relative'>
            <img src='/assets/images/icon/link.svg' className='link_icon' alt='link icon' />
            <input
              type='text'
              className='referral_input form-control'
              placeholder='Enter your Referral Address...'
              value={refAddress}
              onChange={(event) => {
                if (!loading) {
                  setRefAddress(event.target.value)
                }
              }}
            />
          </div>
        </div>
        <div className='text-center'>
          <botton type="button" className="btn modal_btn" onClick={() => buy(refAddress, setRefAddress)} >
            {loading ? 'Loading...' : 'Buy Now'}
          </botton>
        </div>
      </Modal.Body>
    </Modal>
  )
}
