import React from 'react'
import Header from '../components/Header'
import BackToTop from '../components/BackToTop'

function NftMembership() {
    return (
        <div className="projects" data-theme="dark" data-menu="fixed" data-footer-effect="on" data-footer-corners="on">
            <Header />
            <main className="ms-main">
                <div className="ms-page-content">
                    {/*================= Banner Area Start =================*/}
                    <section className="ms-hero services">
                        <div className="ms-parallax jarallax-img nft-img" data-speed="0.7" data-type="scroll">
                            <div className="ms-hc">
                                <div className="ms-hc--inner">
                                    <p className="ms-hero-subtitle">The Elite Founder NFT<br /> Membership</p>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/*================= Banner Area End =================*/}
                    {/*================= Services Area Start =================*/}
                    <div className="services-area-2 services">
                        <div className="container">
                            <div className="services-area-inner">
                                <div className="services-bottom">
                                    <div className="services-item">
                                        <div className="row">
                                            <div className="col-lg-4 col-md-6 text-center">
                                                <div className="ms-sb img-top">
                                                    <div className="ms-sb--img boxed one">
                                                        <img src="/assets/images/icon/nft_membership/exclusive_status.svg" className="attachment-full size-full" alt="image" />
                                                    </div>
                                                    <div className="ms-sb--inner">
                                                        <h3 className="ms-sb--title">
                                                            <span>Exclusive Status</span>
                                                        </h3>
                                                        <p className="ms-sb--text">Recognized as early believers in our vision.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 text-center">
                                                <div className="ms-sb img-top">
                                                    <div className="ms-sb--img boxed one">
                                                        <img src="/assets/images/icon/nft_membership/limited_editions.svg" className="attachment-full size-full" alt="image" />
                                                    </div>
                                                    <div className="ms-sb--inner">
                                                        <h3 className="ms-sb--title">
                                                            <span>Limited Edition</span>
                                                        </h3>
                                                        <p className="ms-sb--text">Ensuring rarity and exclusivity</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 text-center">
                                                <div className="ms-sb img-top">
                                                    <div className="ms-sb--img boxed one">
                                                        <img src="/assets/images/icon/nft_membership/privileged_access.svg" className="attachment-full size-full" alt="image" />
                                                    </div>
                                                    <div className="ms-sb--inner">
                                                        <h3 className="ms-sb--title">
                                                            <span>Privileged Access</span>
                                                        </h3>
                                                        <p className="ms-sb--text">Early access to features and exclusive events.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 text-center">
                                                <div className="ms-sb img-top">
                                                    <div className="ms-sb--img boxed one">
                                                        <img src="/assets/images/icon/nft_membership/airdrop_benefits.svg" className="attachment-full size-full" alt="image" />
                                                    </div>
                                                    <div className="ms-sb--inner">
                                                        <h3 className="ms-sb--title">
                                                            <span>Airdrop Benefits</span>
                                                        </h3>
                                                        <p className="ms-sb--text">Periodic rewards based on NFT status.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 text-center">
                                                <div className="ms-sb img-top">
                                                    <div className="ms-sb--img boxed one">
                                                        <img src="/assets/images/icon/nft_membership/voting_rights.svg" className="attachment-full size-full" alt="image" />
                                                    </div>
                                                    <div className="ms-sb--inner">
                                                        <h3 className="ms-sb--title">
                                                            <span>Voting Rights</span>
                                                        </h3>
                                                        <p className="ms-sb--text">Influence key platform decisions.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 text-center">
                                                <div className="ms-sb img-top">
                                                    <div className="ms-sb--img boxed one">
                                                        <img src="/assets/images/icon/nft_membership/enhance_tokenomic.svg" className="attachment-full size-full" alt="image" />
                                                    </div>
                                                    <div className="ms-sb--inner">
                                                        <h3 className="ms-sb--title">
                                                            <span>Enhanced Tokenomics</span>
                                                        </h3>
                                                        <p className="ms-sb--text">Rewarding our early supporters.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 text-center">
                                                <div className="ms-sb img-top">
                                                    <div className="ms-sb--img boxed one">
                                                        <img src="/assets/images/icon/nft_membership/revenue_sharing.svg" className="attachment-full size-full" alt="image" />
                                                    </div>
                                                    <div className="ms-sb--inner">
                                                        <h3 className="ms-sb--title">
                                                            <span>Revenue Sharing</span>
                                                        </h3>
                                                        <p className="ms-sb--text">Receive a share of 3% from Phase 1 NFT
                                                            distribution revenue monthly until completion.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 text-center">
                                                <div className="ms-sb img-top">
                                                    <div className="ms-sb--img boxed one">
                                                        <img src="/assets/images/icon/nft_membership/future_appreciation.svg" className="attachment-full size-full" alt="image" />
                                                    </div>
                                                    <div className="ms-sb--inner">
                                                        <h3 className="ms-sb--title">
                                                            <span>Future Appreciation</span>
                                                        </h3>
                                                        <p className="ms-sb--text">Value growth as the platform evolves.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 text-center">
                                                <div className="ms-sb img-top">
                                                    <div className="ms-sb--img boxed one">
                                                        <img src="/assets/images/icon/nft_membership/legacy_benefits.svg" className="attachment-full size-full" alt="image" />
                                                    </div>
                                                    <div className="ms-sb--inner">
                                                        <h3 className="ms-sb--title">
                                                            <span>Legacy Benefits</span>
                                                        </h3>
                                                        <p className="ms-sb--text">Pass down privileges to future generations.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 text-center">
                                                <div className="ms-sb img-top">
                                                    <div className="ms-sb--img boxed one">
                                                        <img src="/assets/images/icon/nft_membership/community_recognition.svg" className="attachment-full size-full" alt="image" />
                                                    </div>
                                                    <div className="ms-sb--inner">
                                                        <h3 className="ms-sb--title">
                                                            <span>Community Recognition</span>
                                                        </h3>
                                                        <p className="ms-sb--text">Celebrated for early project support.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 text-center">
                                                <div className="ms-sb img-top">
                                                    <div className="ms-sb--img boxed one">
                                                        <img src="/assets/images/icon/nft_membership/special_collaborations.svg" className="attachment-full size-full" alt="image" />
                                                    </div>
                                                    <div className="ms-sb--inner">
                                                        <h3 className="ms-sb--title">
                                                            <span>Special Collaborations</span>
                                                        </h3>
                                                        <p className="ms-sb--text">Exclusive partnerships for NFT holders.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 text-center">
                                                <div className="ms-sb img-top">
                                                    <div className="ms-sb--img boxed one">
                                                        <img src="/assets/images/icon/nft_membership/secure_authentic.svg" className="attachment-full size-full" alt="image" />
                                                    </div>
                                                    <div className="ms-sb--inner">
                                                        <h3 className="ms-sb--title">
                                                            <span>Secure &amp; Authentic</span>
                                                        </h3>
                                                        <p className="ms-sb--text">Verifiably unique on the blockchain.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 text-center">
                                                <div className="ms-sb img-top">
                                                    <div className="ms-sb--img boxed one">
                                                        <img src="/assets/images/icon/nft_membership/a_part_of_history.svg" className="attachment-full size-full" alt="image" />
                                                    </div>
                                                    <div className="ms-sb--inner">
                                                        <h3 className="ms-sb--title">
                                                            <span>A Part of History</span>
                                                        </h3>
                                                        <p className="ms-sb--text">Become a legacy holder in our project's
                                                            journey.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*================= Services Area End =================*/}
                </div>
            </main>

            <BackToTop />
        </div>
    )
}

export default NftMembership