import { UnsupportedChainIdError, useWeb3React } from "@web3-react/core"
import { NoEthereumProviderError, UserRejectedRequestError as UserRejectedRequestErrorInjected } from "@web3-react/injected-connector"
import { UserRejectedRequestError as UserRejectedRequestErrorWalletConnect, WalletConnectConnector } from '@web3-react/walletconnect-connector'
import { setupNetwork } from "../helpers"
import { toast } from "react-hot-toast"

const useAuth = () => {
    const { activate, deactivate, setError } = useWeb3React()

    const login = async (connector) => {
        if (typeof connector !== 'function' && connector) {
            activate(connector, async (error) => {
                if (error instanceof UnsupportedChainIdError) {
                    setError(error)
                    const provider = await connector.getProvider()
                    const hasSetup = await setupNetwork(provider, deactivate)
                    if (hasSetup) {
                        await activate(connector)
                    }
                }
                if (error instanceof NoEthereumProviderError) {
                    window.open('https://metamask.io/download/', '_blank')
                } else if (error instanceof UserRejectedRequestErrorInjected || error instanceof UserRejectedRequestErrorWalletConnect) {
                    if (connector instanceof WalletConnectConnector) {
                        const walletConnector = connector
                        walletConnector.walletConnectProvider = null
                    }
                    toast.error('Authorization Error')
                }

            })
        } else {
            toast.error("Can't find connector")
        }
    }

    return { login, logout: deactivate }
}

export default useAuth
