import React from 'react'
import Header from '../components/Header'
import BackToTop from '../components/BackToTop'

function MemberClub() {
    return (
        <div className="projects" data-theme="dark" data-menu="fixed" data-footer-effect="on" data-footer-corners="on">
            <Header />
            <main className="ms-main">
                <div className="ms-page-content">
                    {/*================= Banner Area Start =================*/}
                    <section className="ms-hero services">
                        <div className="ms-parallax jarallax-img nft-img member_club" data-speed="0.7" data-type="scroll">
                            <div className="ms-hc">
                                <div className="ms-hc--inner">
                                    <p className="ms-hero-subtitle">Elite<br /> Member Club</p>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/*================= Banner Area End =================*/}
                    {/*================= Services Area Start =================*/}
                    <div className="services-area-2 services">
                        <div className="container">
                            <div className="services-area-inner">
                                <div className="services-bottom">
                                    <div className="services-item">
                                        <div className="row">
                                            <div className="col-lg-4 col-md-6 text-center">
                                                <div className="ms-sb img-top">
                                                    <div className="ms-sb--img boxed one">
                                                        <img src="/assets/images/icon/elite_member_clube/exclushive_membership.svg" className="attachment-full size-full" alt="image" />
                                                    </div>
                                                    <div className="ms-sb--inner">
                                                        <h3 className="ms-sb--title">
                                                            <span>Exclusive Membership</span>
                                                        </h3>
                                                        <p className="ms-sb--text">Join the ranks of the elite in the NFT
                                                            world, with unparalleled privileges and benefits.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 text-center">
                                                <div className="ms-sb img-top">
                                                    <div className="ms-sb--img boxed one">
                                                        <img src="/assets/images/icon/elite_member_clube/assets_managments_tools.svg" className="attachment-full size-full" alt="image" />
                                                    </div>
                                                    <div className="ms-sb--inner">
                                                        <h3 className="ms-sb--title">
                                                            <span>Asset Management Tools</span>
                                                        </h3>
                                                        <p className="ms-sb--text">Comprehensive tools to manage,
                                                            diversify, and optimize your NFT portfolio.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 text-center">
                                                <div className="ms-sb img-top">
                                                    <div className="ms-sb--img boxed one">
                                                        <img src="/assets/images/icon/elite_member_clube/priority_access.svg" className="attachment-full size-full" alt="image" />
                                                    </div>
                                                    <div className="ms-sb--inner">
                                                        <h3 className="ms-sb--title">
                                                            <span>Priority Access</span>
                                                        </h3>
                                                        <p className="ms-sb--text">Get first dibs on exclusive NFT drops,
                                                            collaborations, and events.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 text-center">
                                                <div className="ms-sb img-top">
                                                    <div className="ms-sb--img boxed one">
                                                        <img src="/assets/images/icon/elite_member_clube/networking_opportunities.svg" className="attachment-full size-full" alt="image" />
                                                    </div>
                                                    <div className="ms-sb--inner">
                                                        <h3 className="ms-sb--title">
                                                            <span>Networking Opportunities</span>
                                                        </h3>
                                                        <p className="ms-sb--text">Connect with top-tier artists,
                                                            collectors, and NFT enthusiasts in exclusive member-only
                                                            events.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 text-center">
                                                <div className="ms-sb img-top">
                                                    <div className="ms-sb--img boxed one">
                                                        <img src="/assets/images/icon/elite_member_clube/advanced_analytics.svg" className="attachment-full size-full" alt="image" />
                                                    </div>
                                                    <div className="ms-sb--inner">
                                                        <h3 className="ms-sb--title">
                                                            <span>Advanced Analytics</span>
                                                        </h3>
                                                        <p className="ms-sb--text">Gain insights into market trends, potential
                                                            investments, and the value of your assets.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 text-center">
                                                <div className="ms-sb img-top">
                                                    <div className="ms-sb--img boxed one">
                                                        <img src="/assets/images/icon/elite_member_clube/educational_workshops.svg" className="attachment-full size-full" alt="image" />
                                                    </div>
                                                    <div className="ms-sb--inner">
                                                        <h3 className="ms-sb--title">
                                                            <span>Educational Workshops</span>
                                                        </h3>
                                                        <p className="ms-sb--text">Exclusive sessions to enhance your
                                                            understanding of the NFT market and its intricacies.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 text-center">
                                                <div className="ms-sb img-top">
                                                    <div className="ms-sb--img boxed one">
                                                        <img src="/assets/images/icon/elite_member_clube/dedicated_support.svg" className="attachment-full size-full" alt="image" />
                                                    </div>
                                                    <div className="ms-sb--inner">
                                                        <h3 className="ms-sb--title">
                                                            <span>Dedicated Support</span>
                                                        </h3>
                                                        <p className="ms-sb--text">Priority assistance from our expert team,
                                                            ensuring a seamless experience.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 text-center">
                                                <div className="ms-sb img-top">
                                                    <div className="ms-sb--img boxed one">
                                                        <img src="/assets/images/icon/elite_member_clube/member_only_discount.svg" className="attachment-full size-full" alt="image" />
                                                    </div>
                                                    <div className="ms-sb--inner">
                                                        <h3 className="ms-sb--title">
                                                            <span>Member-Only Discounts</span>
                                                        </h3>
                                                        <p className="ms-sb--text">Enjoy reduced fees, special offers,
                                                            and promotions available only to club members.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 text-center">
                                                <div className="ms-sb img-top">
                                                    <div className="ms-sb--img boxed one">
                                                        <img src="/assets/images/icon/elite_member_clube/voting_rights.svg" className="attachment-full size-full" alt="image" />
                                                    </div>
                                                    <div className="ms-sb--inner">
                                                        <h3 className="ms-sb--title">
                                                            <span>Voting Rights</span>
                                                        </h3>
                                                        <p className="ms-sb--text">Influence the direction of the platform by
                                                            participating in key decisions and initiatives.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 text-center">
                                                <div className="ms-sb img-top">
                                                    <div className="ms-sb--img boxed one">
                                                        <img src="/assets/images/icon/elite_member_clube/regular_rewards.svg" className="attachment-full size-full" alt="image" />
                                                    </div>
                                                    <div className="ms-sb--inner">
                                                        <h3 className="ms-sb--title">
                                                            <span>Regular Rewards</span>
                                                        </h3>
                                                        <p className="ms-sb--text">Earn rewards, bonuses, and incentives for
                                                            active participation and contributions to the community.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 text-center">
                                                <div className="ms-sb img-top">
                                                    <div className="ms-sb--img boxed one">
                                                        <img src="/assets/images/icon/elite_member_clube/collaborative_ventures.svg" className="attachment-full size-full" alt="image" />
                                                    </div>
                                                    <div className="ms-sb--inner">
                                                        <h3 className="ms-sb--title">
                                                            <span>Collaborative Ventures</span>
                                                        </h3>
                                                        <p className="ms-sb--text">Engage in joint ventures, partnerships,
                                                            and collaborations exclusive to club members.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 text-center">
                                                <div className="ms-sb img-top">
                                                    <div className="ms-sb--img boxed one">
                                                        <img src="/assets/images/icon/elite_member_clube/sustainability_inities.svg" className="attachment-full size-full" alt="image" />
                                                    </div>
                                                    <div className="ms-sb--inner">
                                                        <h3 className="ms-sb--title">
                                                            <span>Sustainability Initiatives</span>
                                                        </h3>
                                                        <p className="ms-sb--text">Be part of our eco-friendly endeavors,
                                                            promoting green NFTs and sustainable practices.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*================= Services Area End =================*/}
                </div>
            </main>

            <BackToTop />
        </div>
    )
}

export default MemberClub