import React from 'react'
import Header from '../components/Header'
import BackToTop from '../components/BackToTop'

function Swap() {
    return (
        <div className="projects" data-theme="dark" data-menu="fixed" data-footer-effect="on" data-footer-corners="on">
            <Header />
            <main className="ms-main">
                <div className="ms-page-content">
                    {/*================= Banner Area Start =================*/}
                    <section className="ms-hero services">
                        <div className="ms-parallax jarallax-img swap-img" data-speed="0.7" data-type="scroll">
                            <div className="ms-hc">
                                <div className="ms-hc--inner">
                                    <p className="ms-hero-subtitle">Swap Hub</p>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/*================= Banner Area End =================*/}
                    {/*================= Services Area Start =================*/}
                    <div className="services-area-2 services">
                        <div className="container">
                            <div className="services-area-inner">
                                <div className="services-bottom">
                                    <div className="services-item">
                                        <div className="row">
                                            <div className="col-lg-4 col-md-6 text-center">
                                                <div className="ms-sb img-top">
                                                    <div className="ms-sb--img boxed one">
                                                        <img src="/assets/images/icon/swap_hub/efficient_swapping.svg" className="attachment-full size-full" alt="image" />
                                                    </div>
                                                    <div className="ms-sb--inner">
                                                        <h3 className="ms-sb--title">
                                                            <span>Efficient Swapping</span>
                                                        </h3>
                                                        <p className="ms-sb--text">Seamlessly interact with your NFTs and
                                                            other digital assets in a user-friendly environment.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 text-center">
                                                <div className="ms-sb img-top">
                                                    <div className="ms-sb--img boxed one">
                                                        <img src="/assets/images/icon/swap_hub/competitive_rates.svg" className="attachment-full size-full" alt="image" />
                                                    </div>
                                                    <div className="ms-sb--inner">
                                                        <h3 className="ms-sb--title">
                                                            <span>Competitive Rates</span>
                                                        </h3>
                                                        <p className="ms-sb--text">Benefit from optimal rates, ensuring value
                                                            for your assets.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 text-center">
                                                <div className="ms-sb img-top">
                                                    <div className="ms-sb--img boxed one">
                                                        <img src="/assets/images/icon/swap_hub/multi_blockchain_support.svg" className="attachment-full size-full" alt="image" />
                                                    </div>
                                                    <div className="ms-sb--inner">
                                                        <h3 className="ms-sb--title">
                                                            <span>Multi-Blockchain Support</span>
                                                        </h3>
                                                        <p className="ms-sb--text">Interact across various blockchains,
                                                            expanding your digital asset horizons.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 text-center">
                                                <div className="ms-sb img-top">
                                                    <div className="ms-sb--img boxed one">
                                                        <img src="/assets/images/icon/swap_hub/liquidity_pools.svg" className="attachment-full size-full" alt="image" />
                                                    </div>
                                                    <div className="ms-sb--inner">
                                                        <h3 className="ms-sb--title">
                                                            <span>Liquidity Pools</span>
                                                        </h3>
                                                        <p className="ms-sb--text">Participate in pools, ensuring quick and smooth
                                                            interactions.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 text-center">
                                                <div className="ms-sb img-top">
                                                    <div className="ms-sb--img boxed one">
                                                        <img src="/assets/images/icon/swap_hub/secure_transactions.svg" className="attachment-full size-full" alt="image" />
                                                    </div>
                                                    <div className="ms-sb--inner">
                                                        <h3 className="ms-sb--title">
                                                            <span>Secure Transactions</span>
                                                        </h3>
                                                        <p className="ms-sb--text">Advanced security protocols safeguard
                                                            your assets</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 text-center">
                                                <div className="ms-sb img-top">
                                                    <div className="ms-sb--img boxed one">
                                                        <img src="/assets/images/icon/swap_hub/real_time_analysis.svg" className="attachment-full size-full" alt="image" />
                                                    </div>
                                                    <div className="ms-sb--inner">
                                                        <h3 className="ms-sb--title">
                                                            <span>Real-time Analytics</span>
                                                        </h3>
                                                        <p className="ms-sb--text">Gain insights into trends and potential
                                                            opportunities.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 text-center">
                                                <div className="ms-sb img-top">
                                                    <div className="ms-sb--img boxed one">
                                                        <img src="/assets/images/icon/swap_hub/user_centric_design.svg" className="attachment-full size-full" alt="image" />
                                                    </div>
                                                    <div className="ms-sb--inner">
                                                        <h3 className="ms-sb--title">
                                                            <span>User-Centric Design</span>
                                                        </h3>
                                                        <p className="ms-sb--text">An intuitive interface designed for both
                                                            newcomers and seasoned digital asset enthusiasts.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 text-center">
                                                <div className="ms-sb img-top">
                                                    <div className="ms-sb--img boxed one">
                                                        <img src="/assets/images/icon/swap_hub/educational_resources.svg" className="attachment-full size-full" alt="image" />
                                                    </div>
                                                    <div className="ms-sb--inner">
                                                        <h3 className="ms-sb--title">
                                                            <span>Educational Resources</span>
                                                        </h3>
                                                        <p className="ms-sb--text">Access tutorials, webinars, and guides to
                                                            enhance your understanding.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 text-center">
                                                <div className="ms-sb img-top">
                                                    <div className="ms-sb--img boxed one">
                                                        <img src="/assets/images/icon/swap_hub/community_engagements.svg" className="attachment-full size-full" alt="image" />
                                                    </div>
                                                    <div className="ms-sb--inner">
                                                        <h3 className="ms-sb--title">
                                                            <span>Community Engagement</span>
                                                        </h3>
                                                        <p className="ms-sb--text">Join discussions, provide feedback,
                                                            and connect with other members in the Swap Hub community.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 text-center">
                                                <div className="ms-sb img-top">
                                                    <div className="ms-sb--img boxed one">
                                                        <img src="/assets/images/icon/swap_hub/regular_updates.svg" className="attachment-full size-full" alt="image" />
                                                    </div>
                                                    <div className="ms-sb--inner">
                                                        <h3 className="ms-sb--title">
                                                            <span>Regular Updates</span>
                                                        </h3>
                                                        <p className="ms-sb--text">Stay informed with regular platform updates
                                                            and new features.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 text-center">
                                                <div className="ms-sb img-top">
                                                    <div className="ms-sb--img boxed one">
                                                        <img src="/assets/images/icon/swap_hub/dedicated_support.svg" className="attachment-full size-full" alt="image" />
                                                    </div>
                                                    <div className="ms-sb--inner">
                                                        <h3 className="ms-sb--title">
                                                            <span>Dedicated Support</span>
                                                        </h3>
                                                        <p className="ms-sb--text">Round-the-clock assistance to address
                                                            queries, ensuring a seamless experience.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 text-center">
                                                <div className="ms-sb img-top">
                                                    <div className="ms-sb--img boxed one">
                                                        <img src="/assets/images/icon/swap_hub/eco_friendly_intiatives.svg" className="attachment-full size-full" alt="image" />
                                                    </div>
                                                    <div className="ms-sb--inner">
                                                        <h3 className="ms-sb--title">
                                                            <span>Eco-Friendly Initiatives</span>
                                                        </h3>
                                                        <p className="ms-sb--text">Our commitment to sustainability
                                                            ensures that every interaction is environmentally conscious.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*================= Services Area End =================*/}
                </div>
            </main>

            <BackToTop />
        </div>
    )
}

export default Swap