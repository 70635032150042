import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Games from './pages/Games';
import MemberClub from './pages/MemberClub';
import Metaverse from './pages/Metaverse';
import NftMembership from './pages/NftMembership';
import PrimeHub from './pages/PrimeHub';
import PurchaseNFT from './pages/PurchaseNFT';
import Shopping from './pages/Shopping';
import Swap from './pages/Swap';
import Travel from './pages/Travel';
import Home from './pages/Home';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/games' element={<Games />} />
        <Route path='/memberclub' element={<MemberClub />} />
        <Route path='/metaverse' element={<Metaverse />} />
        <Route path='/nftmembership' element={<NftMembership />} />
        <Route path='/primehub' element={<PrimeHub />} />
        <Route path='/purchase/nft' element={<PurchaseNFT />} />
        <Route path='/shopping' element={<Shopping />} />
        <Route path='/swap' element={<Swap />} />
        <Route path='/travel' element={<Travel />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
