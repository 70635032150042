import React from 'react'
import Header from '../components/Header'
import BackToTop from '../components/BackToTop'

function Shopping() {
    return (
        <div>
            <div className="projects" data-theme="dark" data-menu="fixed" data-footer-effect="on" data-footer-corners="on">
                <Header />
                <main className="ms-main">
                    <div className="ms-page-content">
                        {/*================= Banner Area Start =================*/}
                        <section className="ms-hero services">
                            <div className="ms-parallax jarallax-img shopping-img" data-speed="0.7" data-type="scroll">
                                <div className="ms-hc">
                                    <div className="ms-hc--inner">
                                        <p className="ms-hero-subtitle">Shopping</p>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/*================= Banner Area End =================*/}
                        {/*================= Services Area Start =================*/}
                        <div className="services-area-2 services">
                            <div className="container">
                                <div className="services-area-inner">
                                    <div className="services-bottom">
                                        <div className="services-item">
                                            <div className="row">
                                                <div className="col-lg-4 col-md-6 text-center">
                                                    <div className="ms-sb img-top">
                                                        <div className="ms-sb--img boxed one">
                                                            <img src="/assets/images/icon/shopping/seamless_shopping_experience.svg" className="attachment-full size-full" alt="image" />
                                                        </div>
                                                        <div className="ms-sb--inner">
                                                            <h3 className="ms-sb--title">
                                                                <span>Seamless Shopping Experience</span>
                                                            </h3>
                                                            <p className="ms-sb--text">Intuitive interface designed for
                                                                all users, from tech-savvy to beginners.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-6 text-center">
                                                    <div className="ms-sb img-top">
                                                        <div className="ms-sb--img boxed one">
                                                            <img src="/assets/images/icon/shopping/diverse_product_range.svg" className="attachment-full size-full" alt="image" />
                                                        </div>
                                                        <div className="ms-sb--inner">
                                                            <h3 className="ms-sb--title">
                                                                <span>Diverse Product Range</span>
                                                            </h3>
                                                            <p className="ms-sb--text">A curated selection of high-quality
                                                                products, from digital assets to physical goods.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-6 text-center">
                                                    <div className="ms-sb img-top">
                                                        <div className="ms-sb--img boxed one">
                                                            <img src="/assets/images/icon/shopping/secure_transactions.svg" className="attachment-full size-full" alt="image" />
                                                        </div>
                                                        <div className="ms-sb--inner">
                                                            <h3 className="ms-sb--title">
                                                                <span>Secure Transactions</span>
                                                            </h3>
                                                            <p className="ms-sb--text">Advanced encryption and security
                                                                protocols to ensure safe and reliable transactions.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-6 text-center">
                                                    <div className="ms-sb img-top">
                                                        <div className="ms-sb--img boxed one">
                                                            <img src="/assets/images/icon/shopping/global_market_place.svg" className="attachment-full size-full" alt="image" />
                                                        </div>
                                                        <div className="ms-sb--inner">
                                                            <h3 className="ms-sb--title">
                                                                <span>Global Marketplace</span>
                                                            </h3>
                                                            <p className="ms-sb--text">Connect with sellers and buyers from
                                                                around the world, expanding your shopping horizons.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-6 text-center">
                                                    <div className="ms-sb img-top">
                                                        <div className="ms-sb--img boxed one">
                                                            <img src="/assets/images/icon/shopping/personalized_recommendations.svg" className="attachment-full size-full" alt="image" />
                                                        </div>
                                                        <div className="ms-sb--inner">
                                                            <h3 className="ms-sb--title">
                                                                <span>Personalized Recommendations</span>
                                                            </h3>
                                                            <p className="ms-sb--text">AI-driven suggestions tailored
                                                                to your preferences and browsing history.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-6 text-center">
                                                    <div className="ms-sb img-top">
                                                        <div className="ms-sb--img boxed one">
                                                            <img src="/assets/images/icon/shopping/eco_friendly_options.svg" className="attachment-full size-full" alt="image" />
                                                        </div>
                                                        <div className="ms-sb--inner">
                                                            <h3 className="ms-sb--title">
                                                                <span>Eco-Friendly Options</span>
                                                            </h3>
                                                            <p className="ms-sb--text">Prioritizing sellers and products that
                                                                adhere to sustainable and eco-friendly practices.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-6 text-center">
                                                    <div className="ms-sb img-top">
                                                        <div className="ms-sb--img boxed one">
                                                            <img src="/assets/images/icon/shopping/efficient_delivery_system.svg" className="attachment-full size-full" alt="image" />
                                                        </div>
                                                        <div className="ms-sb--inner">
                                                            <h3 className="ms-sb--title">
                                                                <span>Efficient Delivery System</span>
                                                            </h3>
                                                            <p className="ms-sb--text">Partnering with top logistics
                                                                providers to ensure timely and safe delivery of products.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-6 text-center">
                                                    <div className="ms-sb img-top">
                                                        <div className="ms-sb--img boxed one">
                                                            <img src="/assets/images/icon/shopping/customer_support.svg" className="attachment-full size-full" alt="image" />
                                                        </div>
                                                        <div className="ms-sb--inner">
                                                            <h3 className="ms-sb--title">
                                                                <span>Customer Support</span>
                                                            </h3>
                                                            <p className="ms-sb--text">24/7 assistance for any queries, concerns,
                                                                or feedback</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-6 text-center">
                                                    <div className="ms-sb img-top">
                                                        <div className="ms-sb--img boxed one">
                                                            <img src="/assets/images/icon/shopping/easy_return_refund.svg" className="attachment-full size-full" alt="image" />
                                                        </div>
                                                        <div className="ms-sb--inner">
                                                            <h3 className="ms-sb--title">
                                                                <span>Easy Returns &amp; Refunds</span>
                                                            </h3>
                                                            <p className="ms-sb--text">Hassle-free return policy with a focus
                                                                on customer satisfaction.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-6 text-center">
                                                    <div className="ms-sb img-top">
                                                        <div className="ms-sb--img boxed one">
                                                            <img src="/assets/images/icon/shopping/loyality_reward.svg" className="attachment-full size-full" alt="image" />
                                                        </div>
                                                        <div className="ms-sb--inner">
                                                            <h3 className="ms-sb--title">
                                                                <span>Loyalty Rewards</span>
                                                            </h3>
                                                            <p className="ms-sb--text">Earn points with every purchase, redeemable
                                                                for discounts and exclusive deals.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-6 text-center">
                                                    <div className="ms-sb img-top">
                                                        <div className="ms-sb--img boxed one">
                                                            <img src="/assets/images/icon/shopping/community_reviews.svg" className="attachment-full size-full" alt="image" />
                                                        </div>
                                                        <div className="ms-sb--inner">
                                                            <h3 className="ms-sb--title">
                                                                <span>Community Reviews</span>
                                                            </h3>
                                                            <p className="ms-sb--text">Authentic reviews and ratings from the
                                                                community to guide your purchasing decisions.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-6 text-center">
                                                    <div className="ms-sb img-top">
                                                        <div className="ms-sb--img boxed one">
                                                            <img src="/assets/images/icon/shopping/interactive_features.svg" className="attachment-full size-full" alt="image" />
                                                        </div>
                                                        <div className="ms-sb--inner">
                                                            <h3 className="ms-sb--title">
                                                                <span>Interactive Features</span>
                                                            </h3>
                                                            <p className="ms-sb--text">Engage with live product demos, virtual
                                                                try-ons, and augmented reality previews.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*================= Services Area End =================*/}
                    </div>
                </main>

                <BackToTop />
            </div>

        </div>
    )
}

export default Shopping