import React from 'react'
import { Link } from 'react-router-dom'

function Header() {
    return (
        <div>
            <div className="main-header js-main-header auto-hide-header full-width menu-center header--sticky" data-blur="on">
                <div className="main-header__layout">
                    <div className="main-header__inner">
                        <div className="main-header__logo">
                            <div className="logo-dark">
                                <a href="/">
                                    <img src="/assets/images/logo/logo.png" alt="Most" />
                                </a>
                            </div>
                            <div className="logo-light">
                                <a href="/">
                                    <img src="/assets/images/logo/logo.png" alt="Most" />
                                </a>
                            </div>
                        </div>
                        <nav className="main-header__nav js-main-header__nav main-header__default" id="main-header-nav" aria-labelledby="primary-menu">
                            <ul id="primary-menu" className="navbar-nav">
                                <li className="menu-item menu-item-has-children">
                                    <a href="#" title="Member Ship"><span>Member Ship</span></a>
                                </li>
                                <li className="menu-item menu-item-has-children">
                                    <a href="#" title="Swap Hub"><span>Swap Hub</span></a>
                                </li>
                                <li className="menu-item menu-item-has-children">
                                    <a href="#" title="Member Hub"><span>Member Hub</span></a>
                                </li>
                                <li className="menu-item menu-item-has-children">
                                    <a href="#" title="Metaverse"><span>Metaverse</span></a>
                                </li>
                                <li className="menu-item">
                                    <a href="#" title="Games"><span>Games</span></a>
                                </li>
                            </ul>
                        </nav>
                        <div className="menuTrigger"> </div>
                        <div className="main-header--widgets">
                            <div className="ms_theme_mode">
                                {/* <div class="ms_tm--inner">
                      <div class="theme-toggle" id="theme-toggle">
                          <input type="checkbox" id="switcher" class="check" checked="">
                          <svg class="sun-and-moon" aria-hidden="true" width="24" height="24" viewBox="0 0 24 24">
                              <mask class="moon" id="moon-mask">
                                  <rect x="0" y="0" width="100%" height="100%" fill="white"></rect>
                                  <circle cx="24" cy="10" r="6" fill="black"></circle>
                              </mask>
                              <circle class="sun" cx="12" cy="12" r="6" mask="url(#moon-mask)"
                                  fill="currentColor">
                              </circle>
                              <g class="sun-beams" stroke="currentColor">
                                  <line x1="12" y1="1" x2="12" y2="3"></line>
                                  <line x1="12" y1="21" x2="12" y2="23"></line>
                                  <line x1="4.22" y1="4.22" x2="5.64" y2="5.64"></line>
                                  <line x1="18.36" y1="18.36" x2="19.78" y2="19.78"></line>
                                  <line x1="1" y1="12" x2="3" y2="12"></line>
                                  <line x1="21" y1="12" x2="23" y2="12"></line>
                                  <line x1="4.22" y1="19.78" x2="5.64" y2="18.36"></line>
                                  <line x1="18.36" y1="5.64" x2="19.78" y2="4.22"></line>
                              </g>
                          </svg>
                      </div>
                  </div> */}
                                {/* <div className="btn-founder">
                                    <Link to="/purchase/nft" className="btn_purchase purchase_nft  mt-0">Purchase NFT</Link>
                                </div> */}
                                <div className="btn-founder">
                                    <Link to="https://login.elitememberclub.com/login" className="btn_purchase purchase_nft  mt-0">Login</Link>
                                </div>
                            </div>
                            {/* <div class="header__search-icon">
                  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path
                          d="M16.57,16.15A9,9,0,1,0,15,17.46h0l6.25,6.25,1.42-1.42Zm-3-.14a7.07,7.07,0,1,1,1.56-1.28A6.88,6.88,0,0,1,13.59,16Z">
                      </path>
                  </svg>
              </div> */}
                            <div className="header__search-modal">
                                <button className="header__search--close-btn">
                                    <svg className="icon icon--sm" viewBox="0 0 24 24">
                                        <title>Close modal window</title>
                                        <g fill="none" stroke="currentColor" strokeMiterlimit={10} strokeWidth={2} strokeLinecap="round" strokeLinejoin="round">
                                            <line x1={3} y1={3} x2={21} y2={21} />
                                            <line x1={21} y1={3} x2={3} y2={21} />
                                        </g>
                                    </svg>
                                </button>
                                <div className="header__search--inner">
                                    <form role="search" method="get" className="searchform" action="https://theme.madsparrow.me/most/">
                                        <div className="ms-search-widget">
                                            <input type="search" placeholder="Search..." defaultValue name="s" className="search-field" />
                                            <div className="ms-search--btn">
                                                <span className="ms-sb--icon">
                                                    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M16.57,16.15A9,9,0,1,0,15,17.46h0l6.25,6.25,1.42-1.42Zm-3-.14a7.07,7.07,0,1,1,1.56-1.28A6.88,6.88,0,0,1,13.59,16Z">
                                                        </path>
                                                    </svg>
                                                </span>
                                                <input type="submit" className="search-submit" defaultValue />
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <button className="main-header__nav-trigger js-main-header__nav-trigger menu-default ml-0" aria-label="Toggle menu" aria-expanded="false" data-bs-target="#main-header-nav" data-bs-toggle="collapse" aria-controls="main-header-nav">
                            {/* <span>Menu</span> */}
                            <i className="main-header__nav-trigger-icon" aria-hidden="true"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Header