import React from 'react'
import Header from '../components/Header'
import BackToTop from '../components/BackToTop'

function Metaverse() {
    return (
        <div className="projects" data-theme="dark" data-menu="fixed" data-footer-effect="on" data-footer-corners="on">
            <div>
                <Header />
                <main className="ms-main">
                    <div className="ms-page-content">
                        {/*================= Banner Area Start =================*/}
                        <section className="ms-hero services">
                            <div className="ms-parallax jarallax-img" data-speed="0.7" data-type="scroll">
                                <video className='w-100' autoPlay muted loop src="https://d1cihhbb125h48.cloudfront.net/1695118174.mp4" />
                                <div className="ms-hc">
                                    <div className="ms-hc--inner">
                                        <p className="ms-hero-subtitle">Metaverse</p>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/*================= Banner Area End =================*/}
                        {/*================= Services Area Start =================*/}
                        <div className="services-area-2 services">
                            <div className="container">
                                <div className="services-area-inner">
                                    <div className="services-bottom">
                                        <div className="services-item">
                                            <div className="row">
                                                <div className="col-lg-4 col-md-6 text-center">
                                                    <div className="ms-sb img-top">
                                                        <div className="ms-sb--img boxed one">
                                                            <img src="/assets/images/icon/metaverse/immersive_experiences.svg" className="attachment-full size-full" alt="image" />
                                                        </div>
                                                        <div className="ms-sb--inner">
                                                            <h3 className="ms-sb--title">
                                                                <span>Immersive Experiences</span>
                                                            </h3>
                                                            <p className="ms-sb--text">Dive into a digital universe where
                                                                imagination meets reality, offering unparalleled interactive
                                                                experiences.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-6 text-center">
                                                    <div className="ms-sb img-top">
                                                        <div className="ms-sb--img boxed one">
                                                            <img src="/assets/images/icon/metaverse/exclusive_virtual_real_estate.svg" className="attachment-full size-full" alt="image" />
                                                        </div>
                                                        <div className="ms-sb--inner">
                                                            <h3 className="ms-sb--title">
                                                                <span>Exclusive Virtual Real Estate</span>
                                                            </h3>
                                                            <p className="ms-sb--text">Secure prime digital land in soughtafter
                                                                Metaverse locations, offering both enjoyment and potential
                                                                appreciation.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-6 text-center">
                                                    <div className="ms-sb img-top">
                                                        <div className="ms-sb--img boxed one">
                                                            <img src="/assets/images/icon/metaverse/decentralized_digital_economy.svg" className="attachment-full size-full" alt="image" />
                                                        </div>
                                                        <div className="ms-sb--inner">
                                                            <h3 className="ms-sb--title">
                                                                <span>Decentralized Digital Economy</span>
                                                            </h3>
                                                            <p className="ms-sb--text">Engage in a thriving virtual economy,
                                                                from trading virtual goods to offering services and experiences.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-6 text-center">
                                                    <div className="ms-sb img-top">
                                                        <div className="ms-sb--img boxed one">
                                                            <img src="/assets/images/icon/metaverse/avtar_customizations.svg" className="attachment-full size-full" alt="image" />
                                                        </div>
                                                        <div className="ms-sb--inner">
                                                            <h3 className="ms-sb--title">
                                                                <span>Avatar Customization</span>
                                                            </h3>
                                                            <p className="ms-sb--text">Craft a unique digital identity with endless
                                                                customization options for your Metaverse avatar.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-6 text-center">
                                                    <div className="ms-sb img-top">
                                                        <div className="ms-sb--img boxed one">
                                                            <img src="/assets/images/icon/metaverse/interactive_events.svg" className="attachment-full size-full" alt="image" />
                                                        </div>
                                                        <div className="ms-sb--inner">
                                                            <h3 className="ms-sb--title">
                                                                <span>Interactive Events</span>
                                                            </h3>
                                                            <p className="ms-sb--text">Attend virtual concerts, art exhibitions,
                                                                educational seminars, and more, all within the Metaverse.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-6 text-center">
                                                    <div className="ms-sb img-top">
                                                        <div className="ms-sb--img boxed one">
                                                            <img src="/assets/images/icon/metaverse/social_interaction.svg" className="attachment-full size-full" alt="image" />
                                                        </div>
                                                        <div className="ms-sb--inner">
                                                            <h3 className="ms-sb--title">
                                                                <span>Social Interaction</span>
                                                            </h3>
                                                            <p className="ms-sb--text">Connect with individuals from around the globe,
                                                                forging friendships and collaborations in a virtual space.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-6 text-center">
                                                    <div className="ms-sb img-top">
                                                        <div className="ms-sb--img boxed one">
                                                            <img src="/assets/images/icon/metaverse/secure_transactions.svg" className="attachment-full size-full" alt="image" />
                                                        </div>
                                                        <div className="ms-sb--inner">
                                                            <h3 className="ms-sb--title">
                                                                <span>Secure Transactions</span>
                                                            </h3>
                                                            <p className="ms-sb--text">Conduct safe and transparent transactions
                                                                within the Metaverse, backed by blockchain technology
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-6 text-center">
                                                    <div className="ms-sb img-top">
                                                        <div className="ms-sb--img boxed one">
                                                            <img src="/assets/images/icon/metaverse/virtual_business_opportunities.svg" className="attachment-full size-full" alt="image" />
                                                        </div>
                                                        <div className="ms-sb--inner">
                                                            <h3 className="ms-sb--title">
                                                                <span>Virtual Business Opportunities</span>
                                                            </h3>
                                                            <p className="ms-sb--text">Establish digital storefronts, offer
                                                                services, or create unique Metaverse experiences for others to
                                                                enjoy.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-6 text-center">
                                                    <div className="ms-sb img-top">
                                                        <div className="ms-sb--img boxed one">
                                                            <img src="/assets/images/icon/metaverse/eco_friendly_virtual_explorations.svg" className="attachment-full size-full" alt="image" />
                                                        </div>
                                                        <div className="ms-sb--inner">
                                                            <h3 className="ms-sb--title">
                                                                <span>Eco-Friendly Virtual Exploration</span>
                                                            </h3>
                                                            <p className="ms-sb--text">Dive into digital realms without the
                                                                carbon footprint, promoting sustainable entertainment.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-6 text-center">
                                                    <div className="ms-sb img-top">
                                                        <div className="ms-sb--img boxed one">
                                                            <img src="/assets/images/icon/metaverse/education_traning.svg" className="attachment-full size-full" alt="image" />
                                                        </div>
                                                        <div className="ms-sb--inner">
                                                            <h3 className="ms-sb--title">
                                                                <span>Education &amp; Training</span>
                                                            </h3>
                                                            <p className="ms-sb--text">: Access virtual classrooms, workshops, and
                                                                training sessions, enhancing learning through immersive experiences.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-6 text-center">
                                                    <div className="ms-sb img-top">
                                                        <div className="ms-sb--img boxed one">
                                                            <img src="/assets/images/icon/metaverse/seamlesss_integration_with_real_world.svg" className="attachment-full size-full" alt="image" />
                                                        </div>
                                                        <div className="ms-sb--inner">
                                                            <h3 className="ms-sb--title">
                                                                <span>Seamless Integration with Real World</span>
                                                            </h3>
                                                            <p className="ms-sb--text">Sync your Metaverse
                                                                activities with real-world e-commerce, travel, and other services
                                                                for a
                                                                holistic experience.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-6 text-center">
                                                    <div className="ms-sb img-top">
                                                        <div className="ms-sb--img boxed one">
                                                            <img src="/assets/images/icon/metaverse/open_development_platform.svg" className="attachment-full size-full" alt="image" />
                                                        </div>
                                                        <div className="ms-sb--inner">
                                                            <h3 className="ms-sb--title">
                                                                <span>Open Development Platform</span>
                                                            </h3>
                                                            <p className="ms-sb--text">Developers can craft unique
                                                                applications, games, and experiences within the Metaverse, fostering
                                                                innovation and growth.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*================= Services Area End =================*/}
                    </div>
                </main>

                <BackToTop />
            </div>

        </div>
    )
}

export default Metaverse