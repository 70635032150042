import React from 'react'
import Header from '../components/Header'
import BackToTop from '../components/BackToTop'

function PrimeHub() {
    return (
        <div>
            <div className="projects" data-theme="dark" data-menu="fixed" data-footer-effect="on" data-footer-corners="on">
                <Header />
                <main className="ms-main">
                    <div className="ms-page-content">
                        {/*================= Banner Area Start =================*/}
                        <section className="ms-hero services">
                            <div className="ms-parallax jarallax-img nft-img prime-img" data-speed="0.7" data-type="scroll">
                                <div className="ms-hc">
                                    <div className="ms-hc--inner">
                                        <p className="ms-hero-subtitle">Prime Accuss Hub</p>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/*================= Banner Area End =================*/}
                        {/*================= Services Area Start =================*/}
                        <div className="services-area-2 services">
                            <div className="container">
                                <div className="services-area-inner">
                                    <div className="services-bottom">
                                        <div className="services-item">
                                            <div className="row">
                                                <div className="col-lg-4 col-md-6 text-center">
                                                    <div className="ms-sb img-top">
                                                        <div className="ms-sb--img boxed one">
                                                            <img src="/assets/images/icon/prime_accuss_hub/premier_access.svg" className="attachment-full size-full" alt="image" />
                                                        </div>
                                                        <div className="ms-sb--inner">
                                                            <h3 className="ms-sb--title">
                                                                <span>Premier Access</span>
                                                            </h3>
                                                            <p className="ms-sb--text">Early bird opportunities to exclusive NFT drops
                                                                and
                                                                collections.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-6 text-center">
                                                    <div className="ms-sb img-top">
                                                        <div className="ms-sb--img boxed one">
                                                            <img src="/assets/images/icon/prime_accuss_hub/curated_collections.svg" className="attachment-full size-full" alt="image" />
                                                        </div>
                                                        <div className="ms-sb--inner">
                                                            <h3 className="ms-sb--title">
                                                                <span>Curated Collections</span>
                                                            </h3>
                                                            <p className="ms-sb--text">Handpicked NFTs ensuring quality and
                                                                authenticity.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-6 text-center">
                                                    <div className="ms-sb img-top">
                                                        <div className="ms-sb--img boxed one">
                                                            <img src="/assets/images/icon/prime_accuss_hub/user_centric_design.svg" className="attachment-full size-full" alt="image" />
                                                        </div>
                                                        <div className="ms-sb--inner">
                                                            <h3 className="ms-sb--title">
                                                                <span>User-Centric Design</span>
                                                            </h3>
                                                            <p className="ms-sb--text">Intuitive interface for both novice and
                                                                experienced
                                                                users.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-6 text-center">
                                                    <div className="ms-sb img-top">
                                                        <div className="ms-sb--img boxed one">
                                                            <img src="/assets/images/icon/prime_accuss_hub/market_insights.svg" className="attachment-full size-full" alt="image" />
                                                        </div>
                                                        <div className="ms-sb--inner">
                                                            <h3 className="ms-sb--title">
                                                                <span>Market Insights</span>
                                                            </h3>
                                                            <p className="ms-sb--text">Stay ahead with real-time analytics and trend
                                                                predictions.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-6 text-center">
                                                    <div className="ms-sb img-top">
                                                        <div className="ms-sb--img boxed one">
                                                            <img src="/assets/images/icon/prime_accuss_hub/secure_trasactions.svg" className="attachment-full size-full" alt="image" />
                                                        </div>
                                                        <div className="ms-sb--inner">
                                                            <h3 className="ms-sb--title">
                                                                <span>Secure Transactions</span>
                                                            </h3>
                                                            <p className="ms-sb--text">Advanced encryption ensuring safe trades and
                                                                purchases.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-6 text-center">
                                                    <div className="ms-sb img-top">
                                                        <div className="ms-sb--img boxed one">
                                                            <img src="/assets/images/icon/prime_accuss_hub/global_community.svg" className="attachment-full size-full" alt="image" />
                                                        </div>
                                                        <div className="ms-sb--inner">
                                                            <h3 className="ms-sb--title">
                                                                <span>Global Community</span>
                                                            </h3>
                                                            <p className="ms-sb--text">Connect with artists, collectors, and enthusiasts
                                                                worldwide.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-6 text-center">
                                                    <div className="ms-sb img-top">
                                                        <div className="ms-sb--img boxed one">
                                                            <img src="/assets/images/icon/prime_accuss_hub/educational_resources.svg" className="attachment-full size-full" alt="image" />
                                                        </div>
                                                        <div className="ms-sb--inner">
                                                            <h3 className="ms-sb--title">
                                                                <span>Educational Resources</span>
                                                            </h3>
                                                            <p className="ms-sb--text">Demystifying the world of NFTs with webinars,
                                                                articles, and tutorials.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-6 text-center">
                                                    <div className="ms-sb img-top">
                                                        <div className="ms-sb--img boxed one">
                                                            <img src="/assets/images/icon/prime_accuss_hub/eco_frendly.svg" className="attachment-full size-full" alt="image" />
                                                        </div>
                                                        <div className="ms-sb--inner">
                                                            <h3 className="ms-sb--title">
                                                                <span>Eco-Friendly</span>
                                                            </h3>
                                                            <p className="ms-sb--text">Committed to sustainability with energy-efficient
                                                                blockchain solutions</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-6 text-center">
                                                    <div className="ms-sb img-top">
                                                        <div className="ms-sb--img boxed one">
                                                            <img src="/assets/images/icon/prime_accuss_hub/collaborative_ventures.svg" className="attachment-full size-full" alt="image" />
                                                        </div>
                                                        <div className="ms-sb--inner">
                                                            <h3 className="ms-sb--title">
                                                                <span>Collaborative Ventures</span>
                                                            </h3>
                                                            <p className="ms-sb--text">Partnerships with leading artists, creators, and
                                                                platforms.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-6 text-center">
                                                    <div className="ms-sb img-top">
                                                        <div className="ms-sb--img boxed one">
                                                            <img src="/assets/images/icon/prime_accuss_hub/customizable_experience.svg" className="attachment-full size-full" alt="image" />
                                                        </div>
                                                        <div className="ms-sb--inner">
                                                            <h3 className="ms-sb--title">
                                                                <span>Customizable Experience</span>
                                                            </h3>
                                                            <p className="ms-sb--text">Tailor your dashboard to match your interests
                                                                and preferences.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-6 text-center">
                                                    <div className="ms-sb img-top">
                                                        <div className="ms-sb--img boxed one">
                                                            <img src="/assets/images/icon/prime_accuss_hub/regular_updates.svg" className="attachment-full size-full" alt="image" />
                                                        </div>
                                                        <div className="ms-sb--inner">
                                                            <h3 className="ms-sb--title">
                                                                <span>Regular Updates</span>
                                                            </h3>
                                                            <p className="ms-sb--text">Stay updated with the latest features and
                                                                platform
                                                                enhancements.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*================= Services Area End =================*/}
                    </div>
                </main>

                <BackToTop />
            </div>

        </div>
    )
}

export default PrimeHub