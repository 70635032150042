import React from 'react'
import Header from '../components/Header'
import HeroSlider from '../components/HeroSlider'

function Home() {
    return (
        <div className="index-two" data-theme="dark" data-menu="fixed" data-footer-effect="on" data-footer-corners="on">
            <Header />

            <main className="ms-main">
                <div className="banner-horizental">
                    <HeroSlider />
                </div>
            </main>
        </div>
    )
}

export default Home