// polygon testnet
// export const NFT_CONTRACT_ADDRESS = '0x883d7C6E2Aa7260e502C5a51bE6338405749A525'
// export const TOKEN_CONTRACT_ADDRESS = '0x651a98c6f4734c91725ce01f7d44d4ea56520493'
// export const RPC = 'https://polygon-testnet.public.blastapi.io'
// export const EXPLORER_URL = 'https://mumbai.polygonscan.com'
// export const CHAIN_ID = 80001

// polygon mainnet
// export const NFT_CONTRACT_ADDRESS = '0x8136fdad31F8bd728D1Ae97Fb8a91D7B384D7994'
// export const TOKEN_CONTRACT_ADDRESS = '0xc2132D05D31c914a87C6611C10748AEb04B58e8F'
// export const RPC = 'https://polygon-rpc.com'
// export const EXPLORER_URL = 'https://polygonscan.com'
// export const CHAIN_ID = 137

// ether testnet
// export const NFT_CONTRACT_ADDRESS = '0x7c01614629eFFcf6Ed0B697f893eA44006048e20'
// export const TOKEN_CONTRACT_ADDRESS = '0x2Aec7D1Ac85c3D2864774A27E8bd5ad3084ad822'
// export const RPC = 'https://rpc2.sepolia.org'
// export const EXPLORER_URL = 'https://sepolia.etherscan.io'
// export const CHAIN_ID = 11155111

// ether mainnet
// export const NFT_CONTRACT_ADDRESS = '0x6fD1F0bDE379e6de501BDb46DB10e5062D19bA99'
// export const TOKEN_CONTRACT_ADDRESS = '0xdAC17F958D2ee523a2206206994597C13D831ec7'
// export const RPC = 'https://mainnet.infura.io/v3'
// export const EXPLORER_URL = 'https://etherscan.io'
// export const CHAIN_ID = 1

// bsc testnet
// export const NFT_CONTRACT_ADDRESS = '0xDfBc3172FDC0d10B0B7402033C85e7b8C0BC08fC'
// export const TOKEN_CONTRACT_ADDRESS = '0xC88887bCa276Af4D577a54f4F5376875d628c4a7'
// export const RPC = 'https://bsc-testnet.publicnode.com'
// export const EXPLORER_URL = 'https://testnet.bscscan.com'
// export const CHAIN_ID = 97

// bsc mainnet
export const NFT_CONTRACT_ADDRESS = '0x15b78441Df6584D19053A6076b1c0c15070e203a'
export const TOKEN_CONTRACT_ADDRESS = '0x55d398326f99059fF775485246999027B3197955'
export const RPC = 'https://binance.llamarpc.com'
export const EXPLORER_URL = 'https://bscscan.com'
export const CHAIN_ID = 56

export const PRICE_PATTERN = new RegExp('^\\d{0,10}(\\.\\d{0,6})?$|^$') 