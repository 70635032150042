import React from 'react'
import Header from '../components/Header'
import BackToTop from '../components/BackToTop'

function Travel() {
    return (
        <div className="projects" data-theme="dark" data-menu="fixed" data-footer-effect="on" data-footer-corners="on">
            <Header />
            <main className="ms-main">
                <div className="ms-page-content">
                    {/*================= Banner Area Start =================*/}
                    <section className="ms-hero services">
                        <div className="ms-parallax jarallax-img travel-img" data-speed="0.7" data-type="scroll">
                            <div className="ms-hc">
                                <div className="ms-hc--inner">
                                    <p className="ms-hero-subtitle">Travel</p>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/*================= Banner Area End =================*/}
                    {/*================= Services Area Start =================*/}
                    <div className="services-area-2 services">
                        <div className="container">
                            <div className="services-area-inner">
                                <div className="services-bottom">
                                    <div className="services-item">
                                        <div className="row">
                                            <div className="col-lg-4 col-md-6 text-center">
                                                <div className="ms-sb img-top">
                                                    <div className="ms-sb--img boxed one">
                                                        <img src="/assets/images/icon/travel/exclusive_deals.svg" className="attachment-full size-full" alt="image" />
                                                    </div>
                                                    <div className="ms-sb--inner">
                                                        <h3 className="ms-sb--title">
                                                            <span>Exclusive Deals</span>
                                                        </h3>
                                                        <p className="ms-sb--text">Access to unparalleled travel deals not
                                                            available anywhere else, ensuring maximum savings for our
                                                            users.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 text-center">
                                                <div className="ms-sb img-top">
                                                    <div className="ms-sb--img boxed one">
                                                        <img src="/assets/images/icon/travel/decentralized_discount.svg" className="attachment-full size-full" alt="image" />
                                                    </div>
                                                    <div className="ms-sb--inner">
                                                        <h3 className="ms-sb--title">
                                                            <span>Decentralized Discounts</span>
                                                        </h3>
                                                        <p className="ms-sb--text">Revolutionary decentralized travel
                                                            platform offering unique savings opportunities, cutting out
                                                            middlemen and passing the savings directly to travelers.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 text-center">
                                                <div className="ms-sb img-top">
                                                    <div className="ms-sb--img boxed one">
                                                        <img src="/assets/images/icon/travel/amazing_saving.svg" className="attachment-full size-full" alt="image" />
                                                    </div>
                                                    <div className="ms-sb--inner">
                                                        <h3 className="ms-sb--title">
                                                            <span>Amazing Savings</span>
                                                        </h3>
                                                        <p className="ms-sb--text">Whether it's last-minute bookings or planned
                                                            vacations, benefit from significant cost reductions on flights,
                                                            accommodations, and more.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 text-center">
                                                <div className="ms-sb img-top">
                                                    <div className="ms-sb--img boxed one">
                                                        <img src="/assets/images/icon/travel/global_destinations.svg" className="attachment-full size-full" alt="image" />
                                                    </div>
                                                    <div className="ms-sb--inner">
                                                        <h3 className="ms-sb--title">
                                                            <span>Global Destinations</span>
                                                        </h3>
                                                        <p className="ms-sb--text">Explore a vast array of destinations, from
                                                            popular tourist spots to hidden gems.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 text-center">
                                                <div className="ms-sb img-top">
                                                    <div className="ms-sb--img boxed one">
                                                        <img src="/assets/images/icon/travel/personalized_itinearies.svg" className="attachment-full size-full" alt="image" />
                                                    </div>
                                                    <div className="ms-sb--inner">
                                                        <h3 className="ms-sb--title">
                                                            <span>Personalized Itineraries</span>
                                                        </h3>
                                                        <p className="ms-sb--text">Tailored travel plans based on your
                                                            preferences, ensuring a memorable travel experience.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 text-center">
                                                <div className="ms-sb img-top">
                                                    <div className="ms-sb--img boxed one">
                                                        <img src="/assets/images/icon/travel/eco_friendly_travel_options.svg" className="attachment-full size-full" alt="image" />
                                                    </div>
                                                    <div className="ms-sb--inner">
                                                        <h3 className="ms-sb--title">
                                                            <span>Eco-Friendly Travel Options</span>
                                                        </h3>
                                                        <p className="ms-sb--text">Prioritize sustainable travel with
                                                            eco-resorts, green tours, and carbon offset options.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 text-center">
                                                <div className="ms-sb img-top">
                                                    <div className="ms-sb--img boxed one">
                                                        <img src="/assets/images/icon/travel/secure_booking.svg" className="attachment-full size-full" alt="image" />
                                                    </div>
                                                    <div className="ms-sb--inner">
                                                        <h3 className="ms-sb--title">
                                                            <span>Secure Bookings</span>
                                                        </h3>
                                                        <p className="ms-sb--text">Advanced security protocols ensure your
                                                            personal and payment details are always protected.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 text-center">
                                                <div className="ms-sb img-top">
                                                    <div className="ms-sb--img boxed one">
                                                        <img src="/assets/images/icon/travel/customer_support.svg" className="attachment-full size-full" alt="image" />
                                                    </div>
                                                    <div className="ms-sb--inner">
                                                        <h3 className="ms-sb--title">
                                                            <span>24/7 Customer Support</span>
                                                        </h3>
                                                        <p className="ms-sb--text">Whether you're facing a travel hiccup
                                                            or need recommendations, our team is here to assist around the
                                                            clock.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 text-center">
                                                <div className="ms-sb img-top">
                                                    <div className="ms-sb--img boxed one">
                                                        <img src="/assets/images/icon/travel/loyality_rewards.svg" className="attachment-full size-full" alt="image" />
                                                    </div>
                                                    <div className="ms-sb--inner">
                                                        <h3 className="ms-sb--title">
                                                            <span>Loyalty Rewards</span>
                                                        </h3>
                                                        <p className="ms-sb--text">Earn points with every booking, redeemable
                                                            for future discounts and exclusive travel packages.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 text-center">
                                                <div className="ms-sb img-top">
                                                    <div className="ms-sb--img boxed one">
                                                        <img src="/assets/images/icon/travel/community_review_and_insights.svg" className="attachment-full size-full" alt="image" />
                                                    </div>
                                                    <div className="ms-sb--inner">
                                                        <h3 className="ms-sb--title">
                                                            <span>Community Reviews &amp; Insights</span>
                                                        </h3>
                                                        <p className="ms-sb--text">Benefit from authentic
                                                            traveler reviews, tips, and photos to make informed travel
                                                            decisions.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 text-center">
                                                <div className="ms-sb img-top">
                                                    <div className="ms-sb--img boxed one">
                                                        <img src="/assets/images/icon/travel/seamless_integrations.svg" className="attachment-full size-full" alt="image" />
                                                    </div>
                                                    <div className="ms-sb--inner">
                                                        <h3 className="ms-sb--title">
                                                            <span>Seamless Integration</span>
                                                        </h3>
                                                        <p className="ms-sb--text">Effortlessly sync your travel plans with
                                                            ecommerce shopping, ensuring you have all travel essentials
                                                            ready for your trip.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 text-center">
                                                <div className="ms-sb img-top">
                                                    <div className="ms-sb--img boxed one">
                                                        <img src="/assets/images/icon/travel/interactive_travel_features.svg" className="attachment-full size-full" alt="image" />
                                                    </div>
                                                    <div className="ms-sb--inner">
                                                        <h3 className="ms-sb--title">
                                                            <span>Interactive Travel Features</span>
                                                        </h3>
                                                        <p className="ms-sb--text">Dive into immersive experiences
                                                            with virtual tours, AR destination previews, and live local
                                                            experiences before you even set foot on your journey.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*================= Services Area End =================*/}
                </div>
            </main>

            <BackToTop />
        </div>
    )
}

export default Travel