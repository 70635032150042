import { getAddress } from "@ethersproject/address"
import Web3 from "web3"
import { CHAIN_ID, EXPLORER_URL, RPC } from "./constants"
import { AddressZero } from "@ethersproject/constants"

export const web3 = new Web3(new Web3.providers.HttpProvider(RPC))

export const shortenAddress = (address) => {
    return `${address?.substring(0, 6)}...${address?.substring(address?.length - 4)}`
}

export const convertPriceToDecimal = (price) => {
    return Number(price / 10 ** 6)?.toFixed(0)
}

export const convertPriceToWei = (price) => {
    return web3.utils.toWei(price, 'ether')
}

export function isAddress(value) {
    try {
        return getAddress(value)
    } catch {
        return false
    }
}

export function getContract(address, ABI, library) {
    if (!isAddress(address) || address === AddressZero) {
        throw Error(`Invalid 'address' parameter '${address}'.`)
    }
    if (!library) {
        throw Error('Provider not found')
    }
    const web3 = new Web3(library?.provider)
    return new web3.eth.Contract(ABI, address)

}

export const setupNetwork = async (externalProvider, deactivate) => {
    const provider = externalProvider || window.ethereum
    const chainId = parseInt(CHAIN_ID)
    if (provider) {
        try {
            await provider.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: `0x${chainId.toString(16)}` }],
            })
            return true
        } catch (switchError) {
            if (switchError.code === 4001) {
                alert('Error in swith network')
                deactivate()
            }
            if (switchError.code === 4902) {
                try {
                    await provider.request({
                        method: 'wallet_addEthereumChain',
                        params: [
                            {
                                chainId: `0x${chainId.toString(16)}`,
                                chainName: 'Fantom testnet',
                                nativeCurrency: {
                                    name: 'Fantom',
                                    symbol: 'FTM',
                                    decimals: 18,
                                },
                                rpcUrls: [RPC],
                                blockExplorerUrls: [EXPLORER_URL],
                            },
                        ],
                    })
                    return true
                } catch (error) {
                    console.error('Failed to setup the network in Metamask:', error)
                    return false
                }
            }
            return false
        }
    } else {
        console.error("Can't setup the BSC network on metamask because window.ethereum is undefined")
        return false
    }
}
