import React, { useRef, useState } from 'react';
import { Pagination, Navigation, Parallax, Keyboard, Mousewheel, Scrollbar } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";

export default function HeroSlider() {
    return (
        <>
            <Swiper
                speed={1600}
                parallax={true}
                autoplay={true}
                rtl={true}
                loop={true}
                loopFillGroupWithBlank={!0}
                mousewheel={{
                    eventsTarged: ".swiper-slide",
                    sensitivity: 1
                }}
                keyboard={{
                    enabled: true,
                    onlyInViewport: true
                }}

                pagination={{
                    el: ".swiper-pagination",
                    type: 'progressbar',
                }}
                Scrollbar={{
                    hide: false,
                    draggable: true
                }}
                modules={[Parallax, Scrollbar, Pagination, Navigation, Keyboard, Mousewheel]}
                className="mySwiper swiper-container-h"
            >
                <div
                    slot="container-start"
                    className="parallax-bg"
                    style={{
                        'background-image':
                            'url(https://swiperjs.com/demos/images/nature-1.jpg)',
                    }}
                    data-swiper-parallax="-23%"
                ></div>
                <SwiperSlide>
                    <div className="slider-inner" data-swiper-parallax={100}>
                        <img src="/assets/images/slider/1.webp" alt="full_screen-image" />
                        <div className="swiper-content" data-swiper-parallax={2000}>
                            <div className="title-area">
                                {/* title-area */}
                                <h1 className="title"> Elite NFT <br /> Membership </h1>
                                {/* title area */}
                            </div>
                            <p className="disc">
                                Limited to 200 NFTs.. Get Yours Now!
                            </p>
                            <div className="btn-wrap">
                                <a className="btn btn-circle btn--md" target="_blank" role="button" href="/assets/images/doc/card_english.pdf">
                                    <div className="ms-btn--circle">
                                        <div className="circle">
                                            <div className="circle-fill" />
                                            <svg viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg" className="circle-outline">
                                                <circle cx={25} cy={25} r={23} />
                                            </svg>
                                            <div className="circle-icon">
                                                <svg viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg" className="icon-arrow">
                                                    <path d="M0 5.65612V4.30388L8.41874 4.31842L5.05997 0.95965L5.99054 0L10.9923 4.97273L6.00508 9.96L5.07451 9.00035L8.43328 5.64158L0 5.65612Z">
                                                    </path>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="ms-btn--label">
                                        <div className="ms-btn__text">Rewards Doc (English)</div>
                                        <div className="ms-btn__border" />
                                    </div>
                                </a>
                            </div>
                            <div className="btn-wrap mt_10">
                                <a className="btn btn-circle btn--md" target="_blank" role="button" href="/assets/images/doc/card_korean.pdf">
                                    <div className="ms-btn--circle">
                                        <div className="circle">
                                            <div className="circle-fill" />
                                            <svg viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg" className="circle-outline">
                                                <circle cx={25} cy={25} r={23} />
                                            </svg>
                                            <div className="circle-icon">
                                                <svg viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg" className="icon-arrow">
                                                    <path d="M0 5.65612V4.30388L8.41874 4.31842L5.05997 0.95965L5.99054 0L10.9923 4.97273L6.00508 9.96L5.07451 9.00035L8.43328 5.64158L0 5.65612Z">
                                                    </path>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="ms-btn--label">
                                        <div className="ms-btn__text">Rewards Doc (Korean)</div>
                                        <div className="ms-btn__border" />
                                    </div>
                                </a>
                            </div>
                            <div className="btn-wrap mt_10">
                                <a className="btn btn-circle btn--md" role="button" href="nftmembership">
                                    <div className="ms-btn--circle">
                                        <div className="circle">
                                            <div className="circle-fill" />
                                            <svg viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg" className="circle-outline">
                                                <circle cx={25} cy={25} r={23} />
                                            </svg>
                                            <div className="circle-icon">
                                                <svg viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg" className="icon-arrow">
                                                    <path d="M0 5.65612V4.30388L8.41874 4.31842L5.05997 0.95965L5.99054 0L10.9923 4.97273L6.00508 9.96L5.07451 9.00035L8.43328 5.64158L0 5.65612Z">
                                                    </path>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="ms-btn--label">
                                        <div className="ms-btn__text">Learn More</div>
                                        <div className="ms-btn__border" />
                                    </div>
                                </a>
                            </div>
                            <div className="btn-founder">
                                <a href="/purchase/nft" className="btn_purchase">Purchase Elite NFT</a>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="slider-inner" data-swiper-parallax={100}>
                        <img src="/assets/images/slider/3.webp" alt="full_screen-image" />
                        <div className="swiper-content" data-swiper-parallax={2000}>
                            <div className="title-area">
                                {/* title-area */}
                                <h1 className="title"> Elite <br />Member Club </h1>
                                {/* title area */}
                            </div>
                            <p className="disc">
                                Experience the pinnacle of exclusivity <br /> in the Elite Member Club. Launching
                                4th Quarter 2023!
                            </p>
                            <div className="btn-wrap">
                                <a className="btn btn-circle btn--md" role="button" href="/assets/images/doc/club.pdf" target="_blank">
                                    <div className="ms-btn--circle">
                                        <div className="circle">
                                            <div className="circle-fill" />
                                            <svg viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg" className="circle-outline">
                                                <circle cx={25} cy={25} r={23} />
                                            </svg>
                                            <div className="circle-icon">
                                                <svg viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg" className="icon-arrow">
                                                    <path d="M0 5.65612V4.30388L8.41874 4.31842L5.05997 0.95965L5.99054 0L10.9923 4.97273L6.00508 9.96L5.07451 9.00035L8.43328 5.64158L0 5.65612Z">
                                                    </path>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="ms-btn--label">
                                        <div className="ms-btn__text">White Paper</div>
                                        <div className="ms-btn__border" />
                                    </div>
                                </a>
                            </div>
                            <div className="btn-wrap mt_10">
                                <a className="btn btn-circle btn--md" role="button" href="memberclub">
                                    <div className="ms-btn--circle">
                                        <div className="circle">
                                            <div className="circle-fill" />
                                            <svg viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg" className="circle-outline">
                                                <circle cx={25} cy={25} r={23} />
                                            </svg>
                                            <div className="circle-icon">
                                                <svg viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg" className="icon-arrow">
                                                    <path d="M0 5.65612V4.30388L8.41874 4.31842L5.05997 0.95965L5.99054 0L10.9923 4.97273L6.00508 9.96L5.07451 9.00035L8.43328 5.64158L0 5.65612Z">
                                                    </path>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="ms-btn--label">
                                        <div className="ms-btn__text">Learn More</div>
                                        <div className="ms-btn__border" />
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="slider-inner" data-swiper-parallax={100}>
                        <img src="/assets/images/slider/2.webp" alt="full_screen-image" />
                        <div className="swiper-content" data-swiper-parallax={2000}>
                            <div className="title-area">
                                {/* title-area */}
                                <h1 className="title"> Prime<br /> Access Hub </h1>
                                {/* title area */}
                            </div>
                            <p className="disc">
                                Unlock Prime Access Hub for Elite Access to Exclusive <br /> Opportunities and Elite
                                Status… <b>Coming Soon!</b>
                            </p>
                            <div className="btn-wrap">
                                <a className="btn btn-circle btn--md" role="button" href="primehub">
                                    <div className="ms-btn--circle">
                                        <div className="circle">
                                            <div className="circle-fill" />
                                            <svg viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg" className="circle-outline">
                                                <circle cx={25} cy={25} r={23} />
                                            </svg>
                                            <div className="circle-icon">
                                                <svg viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg" className="icon-arrow">
                                                    <path d="M0 5.65612V4.30388L8.41874 4.31842L5.05997 0.95965L5.99054 0L10.9923 4.97273L6.00508 9.96L5.07451 9.00035L8.43328 5.64158L0 5.65612Z">
                                                    </path>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="ms-btn--label">
                                        <div className="ms-btn__text">Learn More</div>
                                        <div className="ms-btn__border" />
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="slider-inner" data-swiper-parallax={100}>
                        <img src="/assets/images/slider/4.webp" alt="full_screen-image" />
                        <div className="swiper-content" data-swiper-parallax={2000}>
                            <div className="title-area">
                                {/* title-area */}
                                <h1 className="title"> Swap Hub </h1> {/* title area */}
                            </div>
                            <p className="disc">
                                Swap Tokens, NFTs and LifeStyle Points Seamlessly <br /> with the Token Swap Hub…
                                <b>Coming Soon!</b>
                            </p>
                            <div className="btn-wrap">
                                <a className="btn btn-circle btn--md" role="button" href="swap">
                                    <div className="ms-btn--circle">
                                        <div className="circle">
                                            <div className="circle-fill" />
                                            <svg viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg" className="circle-outline">
                                                <circle cx={25} cy={25} r={23} />
                                            </svg>
                                            <div className="circle-icon">
                                                <svg viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg" className="icon-arrow">
                                                    <path d="M0 5.65612V4.30388L8.41874 4.31842L5.05997 0.95965L5.99054 0L10.9923 4.97273L6.00508 9.96L5.07451 9.00035L8.43328 5.64158L0 5.65612Z">
                                                    </path>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="ms-btn--label">
                                        <div className="ms-btn__text">Learn More</div>
                                        <div className="ms-btn__border" />
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="slider-inner" data-swiper-parallax={100}>
                        <img src="/assets/images/slider/5.webp" alt="full_screen-image" />
                        <div className="swiper-content" data-swiper-parallax={2000}>
                            <div className="title-area">
                                {/* title-area */}
                                <h1 className="title"> Shopping </h1>
                                {/* title area */}
                            </div>
                            <p className="disc">
                                Shop using your NFT wallet effortlessly in the world <br /> of Elite E- Commerce…
                                <b>Coming Soon!</b>
                            </p>
                            <div className="btn-wrap">
                                <a className="btn btn-circle btn--md" role="button" href="shopping">
                                    <div className="ms-btn--circle">
                                        <div className="circle">
                                            <div className="circle-fill" />
                                            <svg viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg" className="circle-outline">
                                                <circle cx={25} cy={25} r={23} />
                                            </svg>
                                            <div className="circle-icon">
                                                <svg viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg" className="icon-arrow">
                                                    <path d="M0 5.65612V4.30388L8.41874 4.31842L5.05997 0.95965L5.99054 0L10.9923 4.97273L6.00508 9.96L5.07451 9.00035L8.43328 5.64158L0 5.65612Z">
                                                    </path>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="ms-btn--label">
                                        <div className="ms-btn__text">Learn More</div>
                                        <div className="ms-btn__border" />
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="slider-inner" data-swiper-parallax={100}>
                        <img src="/assets/images/slider/6.webp" alt="full_screen-image" />
                        <div className="swiper-content" data-swiper-parallax={2000}>
                            <div className="title-area">
                                {/* title-area */}
                                <h1 className="title"> Travel </h1>
                                {/* title area */}
                            </div>
                            <p className="disc">
                                Embark on Elite and Exclusive Prime <br /> Travel Opportunities… <b>Coming Soon!</b>
                            </p>
                            <div className="btn-wrap">
                                <a className="btn btn-circle btn--md" role="button" href="travel">
                                    <div className="ms-btn--circle">
                                        <div className="circle">
                                            <div className="circle-fill" />
                                            <svg viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg" className="circle-outline">
                                                <circle cx={25} cy={25} r={23} />
                                            </svg>
                                            <div className="circle-icon">
                                                <svg viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg" className="icon-arrow">
                                                    <path d="M0 5.65612V4.30388L8.41874 4.31842L5.05997 0.95965L5.99054 0L10.9923 4.97273L6.00508 9.96L5.07451 9.00035L8.43328 5.64158L0 5.65612Z">
                                                    </path>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="ms-btn--label">
                                        <div className="ms-btn__text">Learn More</div>
                                        <div className="ms-btn__border" />
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="slider-inner" data-swiper-parallax={100}>
                        <div className='d-lg-block d-none'>
                            <video autoPlay muted loop src="https://d1cihhbb125h48.cloudfront.net/1695118174.mp4" className='vh-100' />
                        </div>
                        <div className='d-lg-none d-block'>
                            <video autoPlay muted loop src="https://d1cihhbb125h48.cloudfront.net/1695643470.mp4" className='vh-100' />
                        </div>
                        <div className="swiper-content" data-swiper-parallax={2000}>
                            <div className="title-area">
                                {/* title-area */}
                                <h1 className="title"> Metaverse </h1>
                                {/* title area */}
                            </div>
                            <p className="disc">
                                Dive into the endless possibilities<br /> of the Metaverse. <b>Coming Soon!</b>
                            </p>
                            <div className="btn-wrap">
                                <a className="btn btn-circle btn--md" role="button" href="metaverse">
                                    <div className="ms-btn--circle">
                                        <div className="circle">
                                            <div className="circle-fill" />
                                            <svg viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg" className="circle-outline">
                                                <circle cx={25} cy={25} r={23} />
                                            </svg>
                                            <div className="circle-icon">
                                                <svg viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg" className="icon-arrow">
                                                    <path d="M0 5.65612V4.30388L8.41874 4.31842L5.05997 0.95965L5.99054 0L10.9923 4.97273L6.00508 9.96L5.07451 9.00035L8.43328 5.64158L0 5.65612Z">
                                                    </path>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="ms-btn--label">
                                        <div className="ms-btn__text">Learn More</div>
                                        <div className="ms-btn__border" />
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="slider-inner" data-swiper-parallax={100}>
                        <div className='d-lg-block d-none'>
                            <video autoPlay muted loop src="https://d1cihhbb125h48.cloudfront.net/1694439977.mp4" className='vh-100' />
                        </div>
                        <div className='d-lg-none d-block'>
                            <video autoPlay muted loop src="https://d1cihhbb125h48.cloudfront.net/1695643379.mp4" className='vh-100' />
                        </div>
                        <div className="swiper-content" data-swiper-parallax={2000}>
                            <div className="title-area">
                                {/* title-area */}
                                <h1 className="title"> Games </h1>
                                {/* title area */}
                            </div>
                            <p className="disc">
                                Enter a world of thrilling challenges with <br /> Online Gaming… <b>Coming Soon!</b>
                            </p>
                            <div className="btn-wrap">
                                <a className="btn btn-circle btn--md" role="button" href="games">
                                    <div className="ms-btn--circle">
                                        <div className="circle">
                                            <div className="circle-fill" />
                                            <svg viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg" className="circle-outline">
                                                <circle cx={25} cy={25} r={23} />
                                            </svg>
                                            <div className="circle-icon">
                                                <svg viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg" className="icon-arrow">
                                                    <path d="M0 5.65612V4.30388L8.41874 4.31842L5.05997 0.95965L5.99054 0L10.9923 4.97273L6.00508 9.96L5.07451 9.00035L8.43328 5.64158L0 5.65612Z">
                                                    </path>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="ms-btn--label">
                                        <div className="ms-btn__text">Learn More</div>
                                        <div className="ms-btn__border" />
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <div className="slider-pagination-area">
                    <h5 className="slide-range one">01</h5>
                    <div className="swiper-pagination swiper-pagination-progressbar swiper-pagination-horizontal"><span
                        className="swiper-pagination-progressbar-fill"
                        style={{ 'transform': 'translate3d(0px, 0px, 0px) scaleX(0.666667) scaleY(1)', 'transition-duration': '1500ms' }}></span>
                    </div>
                    <h5 className="slide-range three">08</h5>
                </div>
            </Swiper>
        </>
    )
}